const BaseURL = process.env.REACT_APP_URL;

export const Get_Dates_For_Allocation = `${BaseURL}api.php?action=getDatesForAllocation`;
export const Get_Combined_Allocation_Data = `${BaseURL}api.php?action=getAllocationGrids&`;
export const Get_Staff_List = `${BaseURL}api.php?action=getStaffList&`;
export const Combined_Api = `${BaseURL}api.php?`;
export const Update_User_Details = `${BaseURL}api.php`;
export const Change_Game_Label = `${BaseURL}api.php?action=createUpdateMatchList&`;
export const Get_Edit_History = `${BaseURL}api.php?action=getMatchListHistoryData&`;
export const Check_User_Auth = `${BaseURL}api.php?action=checkAuth&userID=`;
export const Update_Flag_Match = `${BaseURL}api.php?action=updateFlagMatch&`;
export const Remove_Flag_Match = `${BaseURL}api.php?action=updateFlagMatch&`;
export const Get_Allocated_Shift_User = `${BaseURL}api.php?action=getAllocatedShiftUsers&`;
export const Re_Release_Roaster_Send = `${BaseURL}api.php?action=reReleaseRoster&`;
export const ADD_UMPIRE_NOTE = `${BaseURL}api.php?action=saveAllocationShiftNotes`;
export const ADD_ADDITIONAL_ROLE = `${BaseURL}api.php?action=addAdditionalRoles`;
export const REMOVE_ADDITIONAL_ROLE = `${BaseURL}api.php?action=removeAdditionalRoles`;
export const GET_MULTIPLE_EVENTS = `${BaseURL}api.php?action=getMultipleEvents`;
export const ADDITIONAL_ROLE_FLAG = `${BaseURL}api.php?action=updateAdditionalRoleFlag`;
