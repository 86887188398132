export const checkRequiredValidationWithMinMax = (
  text,
  fieldName,
  min,
  max,
  required = true
) => {
  let error = "";
  if (required === true) {
    if (text === "") {
      return (error = fieldName + " field is required");
    }
  }
  if (text.length < min) {
    error = fieldName + "field must be greater than " + min + " characters";
  }
  if (text.length > max) {
    error = fieldName + "field must be less than " + max + " characters";
  }
  return error;
};

export const checkEmailValidation = (emailText, required = true) => {
  let error = "";

  if (required === true) {
    if (emailText === "") {
      return (error = "Email field is required");
    }
  }

  const pattern = /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*@[a-z0-9]+([a-z0-9]+)*(\.[a-z0-9]+([a-z0-9]+)*)*\.[a-z]{2,4}$/;
  if (pattern.test(emailText)) {
    return "";
  } else {
    error = "Bad email address: " + emailText;
  }
  return error;
};

export const checkEmptyValidation = (field, fieldName) => {
  let error = "";

  if (field === "" || field === undefined) {
    error = fieldName + " field is required";
  }
  return error;
};

export const checkMobileNumberValidation = (field) => {
  let error = "";

  if (field === "") {
    error = "This field is required";
  }
  field = parseInt(field);
  if (!Number.isInteger(field)) {
    error = "This field must be a number";
  }
  return error;
};
