import axios from "axios";
import {
  Get_Combined_Allocation_Data,
  Combined_Api,
  Update_User_Details,
  Change_Game_Label,
  Get_Allocated_Shift_User,
  Re_Release_Roaster_Send,
  ADD_UMPIRE_NOTE,
  ADD_ADDITIONAL_ROLE,
  REMOVE_ADDITIONAL_ROLE,
  GET_MULTIPLE_EVENTS,
  ADDITIONAL_ROLE_FLAG,
} from "../api/api";
import { GET_COMBINED_ALLOCATION_DATA, ROSTER_DATA_LOADING } from "./types";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const getCombinedData =
  (assocId, calId, page, manager_id) => async (dispatch) => {
    dispatch(rosterDataLoading(true));
    return await axios
      .get(
        Get_Combined_Allocation_Data +
          `assocID=${assocId}&calID=${calId}&pageNO=${page}&manager_id=${manager_id}`
      )
      .then((res) => {
        dispatch(rosterDataLoading(false));
        dispatch({
          type: GET_COMBINED_ALLOCATION_DATA,
          payload: res.data.result,
        });

        return res;
      })
      .catch((err) => {
        dispatch(rosterDataLoading(false));
        return err;
      });
  };

export const updateAllocationData = (updateObj) => async (dispatch) => {
  return await axios.get(Combined_Api, {
    params: updateObj,
  });
};

export const rosterDataLoading = (data) => async (dispatch) => {
  dispatch({
    type: ROSTER_DATA_LOADING,
    payload: data,
  });
};

export const swapUsers = (managerId, swapObj) => async (dispatch) => {
  const body = JSON.stringify(swapObj);

  return await axios.post(
    `${Update_User_Details}?action=swappingUserRole&manager_id=${managerId}`,
    body,
    config
  );
};

export const closeAllocationDate = (managerId, data) => async (dispatch) => {
  return await axios.post(
    Combined_Api + `action=closeAllocationDate&manager_id=${managerId}`,
    data
  );
};

export const gameLabelChange =
  (assocID, calID, manager_id, data) => async (dispatch) => {
    return await axios.post(
      Change_Game_Label +
        `assocID=${assocID}&calID=${calID}&manager_id=${manager_id}`,
      data
    );
  };

export const getAllocatedShiftUsers =
  (assocId, calId, manager_id) => async (dispatch) => {
    return await axios.get(
      `${Get_Allocated_Shift_User}manager_id=${manager_id}&calID=${calId}&assocID=${assocId}`
    );
  };

export const ReReleaseRoasterSend =
  (assocId, calId, manager_id, userIDS) => async (dispatch) => {
    return await axios.get(
      `${Re_Release_Roaster_Send}manager_id=${manager_id}&calID=${calId}&assocID=${assocId}&userIDS=${userIDS}`
    );
  };

export const addUmpireNote = (data) => async (dispatch) => {
  return await axios.post(ADD_UMPIRE_NOTE, null, { params: data });
};

export const addAdditionalRole = (data) => async (dispatch) => {
  return await axios.post(ADD_ADDITIONAL_ROLE, null, { params: data });
};

export const removeAdditionalRole = (data) => async (dispatch) => {
  return await axios.post(REMOVE_ADDITIONAL_ROLE, null, { params: data });
};

export const getMultipleEvents = (data) => async (dispatch) => {
  return await axios.post(GET_MULTIPLE_EVENTS, null, { params: data });
};

export const updateAdditionalRoleFlag = (data) => async (dispatch) => {
  return await axios.post(ADDITIONAL_ROLE_FLAG, null, { params: data });
};
