import React from "react";
import ContentLoader from "react-content-loader";

export const StaffListLoader = (props) => (
  <ContentLoader height={520} width={165} {...props}>
    <rect x="5" y="8" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="0" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="45" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="36" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="85" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="76" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="125" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="115" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="165" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="156" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="205" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="196" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="245" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="235" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="287" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="277" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="330" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="320" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="374" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="364" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="417" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="406" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="460" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="450" rx="5" ry="5" width="6" height="30" />
    <rect x="5" y="504" rx="5" ry="5" width="122" height="10" />
    <rect x="140" y="493" rx="5" ry="5" width="6" height="30" />
  </ContentLoader>
);
