import {
  GET_COMBINED_ALLOCATION_DATA,
  GET_DATES_FOR_ALLOCATION,
  GET_STAFF_LIST,
  GET_STAFF_LIST_LOADING,
  GET_USER_DETAILS,
} from "../actions/types";

const initialState = {
  staffList: [],
  dates: [],
  user: {},
  userDetailsLoading: true,
  staffListLoading: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_STAFF_LIST:
      return {
        ...state,
        staffList: payload,
      };
    case GET_DATES_FOR_ALLOCATION:
      return {
        ...state,
        dates: payload,
      };
    case GET_STAFF_LIST_LOADING:
      return {
        ...state,
        staffListLoading: payload,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        user: payload,
        userDetailsLoading: false,
      };
    default:
      return state;
  }
}
