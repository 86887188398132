import axios from "axios";
import {
  Combined_Api,
  Get_Dates_For_Allocation,
  Update_User_Details,
  Get_Staff_List,
  Check_User_Auth,
  Update_Flag_Match,
  Remove_Flag_Match
} from "../api/api";
import {
  GET_DATES_FOR_ALLOCATION,
  GET_STAFF_LIST,
  GET_STAFF_LIST_LOADING,
  GET_USER_DETAILS,
} from "./types";

export const getStaffList = (assocId, calId, managerId, type) => async (dispatch) => {
  dispatch(staffListLoading(true));
  return await axios
    .get(Get_Staff_List + `assocID=${assocId}&calID=${calId}&manager_id=${managerId}&type=${type}`)
    .then((res) => {
      // console.log("res", res)
      // if (res.data.success === "AuthFailed") {
      //   dispatch(staffListLoading(false));
      //   dispatch({
      //     type: GET_STAFF_LIST,
      //     payload: [],
      //   });
      // } else {
      //   dispatch(staffListLoading(false));
      //   dispatch({
      //     type: GET_STAFF_LIST,
      //     payload: res.data.result,
      //   });
      // }
      dispatch(staffListLoading(false));
      dispatch({
        type: GET_STAFF_LIST,
        payload: res.data.result,
      });
      return res;
    })
    .catch((err) => {
      staffListLoading(false);
      return err;
    });
};

export const getDatesForAllocation = (assocId, managerId) => async (dispatch) => {
  return await axios
    .get(`${Get_Dates_For_Allocation}&assocID=${assocId}&manager_id=${managerId}`)
    .then((res) => {
      dispatch({
        type: GET_DATES_FOR_ALLOCATION,
        payload: res.data.result,
      });
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const staffListLoading = (data) => async (dispatch) => {
  dispatch({
    type: GET_STAFF_LIST_LOADING,
    payload: data,
  });
};

export const getUserDetails = (assocId, userId, managerId) => async (dispatch) => {
  return await axios
    .get(
      Combined_Api + `action=getUserData&assocID=${assocId}&userID=${userId}&manager_id=${managerId}`
    )
    .then((res) => {
      dispatch({
        type: GET_USER_DETAILS,
        payload: res.data.result,
      });

      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const updateUserDetails = (managerId, formData) => async (dispatch) => {
  return await axios.post(`${Update_User_Details}&manager_id=${managerId}`, formData);
};

export const userCheckAuth = (userId) => async () => {
  const userCheckAuthRes = await axios.get(Check_User_Auth + userId);

  return userCheckAuthRes;
}

export const updateFlagMatch = (timeID, calID, courtID, assocID, reason, manager_id) => async () => {
  const updateFlag = await axios.get(`${Update_Flag_Match}timeID=${timeID}&calID=${calID}&courtID=${courtID}&assocID=${assocID}&reason=${reason}&manager_id=${manager_id}&type=1`);

  return updateFlag;
}

export const removeFlagMatch = (timeID, calID, courtID, assocID, manager_id) => async () => {
  const removeFlag = await axios.get(`${Remove_Flag_Match}timeID=${timeID}&calID=${calID}&courtID=${courtID}&assocID=${assocID}&manager_id=${manager_id}&type=2`);

  return removeFlag;
}