import React from "react";
import ContentLoader from "react-content-loader";

export const CourtsLoader = (props) => (
  <ContentLoader height={70} width={1032} {...props}>
    <rect x="30" y="20" rx="2" ry="5" width="70" height="15" />
    <rect x="160" y="20" rx="2" ry="5" width="70" height="15" />
    <rect x="290" y="20" rx="2" ry="5" width="70" height="15" />
    <rect x="425" y="20" rx="2" ry="5" width="70" height="15" />
    <rect x="555" y="20" rx="2" ry="5" width="70" height="15" />
    <rect x="685" y="20" rx="2" ry="5" width="70" height="15" />
    <rect x="820" y="20" rx="2" ry="5" width="70" height="15" />
    <rect x="948" y="20" rx="2" ry="5" width="70" height="15" />
    <rect x="8" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="68" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="140" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="200" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="272" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="332" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="467" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="406" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="465" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="405" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="536" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="595" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="665" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="724" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="800" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="860" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="932" y="56" rx="2" ry="5" width="52" height="10" />
    <rect x="991" y="56" rx="2" ry="5" width="52" height="10" />
  </ContentLoader>
);
