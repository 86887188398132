import { BrowserRouter as Router, HashRouter, Switch, Route } from "react-router-dom";
import Roster from "./Views/Roster";
import "./assets/css/bootstrap.min.css";
import "./assets/css/style.css";
import { Provider } from "react-redux";
import store from "./store/store";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/:assocId?/:userId?" exact component={Roster} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
