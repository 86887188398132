import React from "react";
import ContentLoader from "react-content-loader";

export const TimeLoader = (props) => (
  <ContentLoader height={100} width={30} {...props}>
    <rect x="0" y="32" rx="2" ry="5" width="30" height="10" />
    <rect x="0" y="55" rx="2" ry="5" width="30" height="10" />
  </ContentLoader>
);

export const NameLoader = (props) => (
  <ContentLoader height={9} width={45} {...props}>
    <rect x="0" y="2" rx="2" ry="5" width="45" height="7" />
  </ContentLoader>
);
export const MatchDataLoader = (props) => (
  <ContentLoader height={65} width={95} {...props}>
    <rect x="0" y="5" rx="2" ry="5" width="120" height="7" />
    <rect x="15" y="21" rx="2" ry="5" width="70" height="7" />
    <rect x="0" y="37" rx="2" ry="5" width="120" height="7" />
    <rect x="15" y="53" rx="2" ry="5" width="70" height="7" />
  </ContentLoader>
);
export const SupervisorLoader = (props) => (
  <ContentLoader height={15} width={95} {...props}>
    <rect x="5" y="2" rx="2" ry="5" width="120" height="10" />
  </ContentLoader>
);
