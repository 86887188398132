import {
  GET_COMBINED_ALLOCATION_DATA,
  ROSTER_DATA_LOADING,
} from "../actions/types";

const initialState = {
  result: {
    totalPages: 0,
    data: {
      releaseStatus: null,
      courtName: [],
      mainTableArray: [],
    },
  },
  rosterDataLoading: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_COMBINED_ALLOCATION_DATA:
      return {
        ...state,
        result: payload,
      };
    case ROSTER_DATA_LOADING:
      return {
        ...state,
        rosterDataLoading: payload,
      };

    default:
      return state;
  }
}
