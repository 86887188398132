import React, { Fragment, useEffect, useState } from "react";
import arrowRight from "../assets/images/arrow-right.svg";
import arrowLeft from "../assets/images/arrow-left.svg";
import filterArrow from "../assets/images/filter-up-arrow.svg";
import calender from "../assets/images/calender-icon.svg";
import filter from "../assets/images/filter-icon.svg";
import bell from "../assets/images/bell-icon.svg";
import undo from "../assets/images/undo.svg";
import deleteBtn from "../assets/images/delete.svg";
import timerIcon from "../assets/images/timer-icon.svg";
import editIcon from "../assets/images/editIcon.svg";
import redFlagIcon from "../assets/images/flag.svg";
import { Modal, Input, Button, Switch } from "antd";
import "antd/dist/antd.css";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getStaffList,
  getDatesForAllocation,
  getUserDetails,
  updateUserDetails,
  userCheckAuth,
  updateFlagMatch,
  removeFlagMatch,
} from "../actions/staffActions";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateFormat from "dateformat";
import {
  closeAllocationDate,
  getCombinedData,
  swapUsers,
  updateAllocationData,
  gameLabelChange,
  getAllocatedShiftUsers,
  ReReleaseRoasterSend,
  addUmpireNote,
  addAdditionalRole,
  removeAdditionalRole,
  getMultipleEvents,
  updateAdditionalRoleFlag,
} from "../actions/rosterActions";
import { StaffListLoader } from "../ContentLoader/StaffListLoader";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { CourtsLoader } from "../ContentLoader/CourtsLoader";
import {
  MatchDataLoader,
  NameLoader,
  SupervisorLoader,
  TimeLoader,
} from "../ContentLoader/TimeslotsLoader";
import Swal from "sweetalert2";
import TopBarProgress from "react-topbar-progress-indicator";
import rosterLogo from "../assets/images/logo.png";
import {
  checkEmptyValidation,
  checkRequiredValidationWithMinMax,
} from "../Helper/validations";
import axios from "axios";
import { Get_Edit_History } from "../api/api";
import Select, { components } from "react-select";

TopBarProgress.config({
  barColors: {
    0: "#2d8ae1",
    "1.0": "#2d8ae1",
  },
  shadowBlur: 5,
});

const Roster = (routerProps) => {
  const {
    getDatesForAllocation,
    getCombinedData,
    getStaffList,
    getUserDetails,
    updateUserDetails,
    updateAllocationData,
    closeAllocationDate,
    roster,
    staff,
    match,
    swapUsers,
    gameLabelChange,
    userCheckAuth,
    updateFlagMatch,
    removeFlagMatch,
    getAllocatedShiftUsers,
    ReReleaseRoasterSend,
    addUmpireNote,
    addAdditionalRole,
    removeAdditionalRole,
    getMultipleEvents,
    updateAdditionalRoleFlag,
  } = routerProps;

  const { assocId, userId } = match.params;

  // To show toggle for additional role
  const [additionalRoleFlag, setAdditionalRoleFlag] = useState("0");

  useEffect(() => {
    userCheckAuth(userId)
      .then(async (res) => {
        if (res.data && res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else {
          const getDatesRes = await getDatesForAllocation(assocId, userId);
          if (getDatesRes?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          }
          setAdditionalRoleFlag(getDatesRes?.data?.moduleFlag);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    const { dates } = staff;
    let nr = new Array();
    let highlighted = new Array();
    if (dates?.length > 0) {
      dates.forEach((e) => {
        nr.push(new Date(e.datestamp));

        if (e.multiple == 1) {
          highlighted.push(new Date(e.datestamp));
        }
      });
    }
    setDatesToAllocate(nr);
    setHighlightedDates(highlighted);
  }, [staff.dates]);

  useEffect(() => {
    if (Object.keys(staff.user).length !== 0) {
      const { user } = staff;

      setUserDetails({
        userID: user.userID,
        firstName: user.givenName,
        lastName: user.lastName,
        email: user.email,
        mobile: user.mobile,
        ecName1: user.emergency_name_1,
        ecNumber1: user.emergency_phone_1,
        ecName2: user.emergency_name_2,
        ecNumber2: user.emergency_phone_2,
      });
    }
  }, [staff.user]);

  useEffect(() => {
    setReleased(roster.result.data.releaseStatus);
  }, [roster]);

  const [showNotification, setShowNotification] = useState(true);
  const [notificationData, setNotificationData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [openDatePicker, setopenDatePicker] = useState(false);
  const [selectedStaffName, setSelectedStaffName] = useState("");
  const [isLowOpacity, setIsLowOpacity] = useState(false);
  const [datesToAllocate, setDatesToAllocate] = useState([]);
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [available, setAvailable] = useState(true);
  const [noAvailable, setNoAvailable] = useState(true);
  const [notAvailable, setNotAvailable] = useState(true);
  const [allocated, setAllocated] = useState(false);
  const [supervisors, setsupervisors] = useState(true);
  const [mentors, setMentors] = useState(true);
  const [isFilterChange, setIsFilterChange] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [calId, setcalId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentName, setCurrentName] = useState(false);
  const [fullWidth, setFullWidth] = useState(false);
  const [showSupervisors, setShowSupervisors] = useState(true);
  const [unallocated, setUnallocated] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [menuType, setMenuType] = useState(null);
  const [adminNotes, setAdminNotes] = useState("");
  const [userComment, setUserComment] = useState("");
  const [allTypes, setAllTypes] = useState("1,2,3");
  const [loading, setLoading] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [released, setReleased] = useState(null);
  const [gameData, setGameData] = useState(null);
  const [dragData, setDragData] = useState(null);
  const [dragTarget, setDragTarget] = useState("");
  const [showContextMenu, setShowContextMenu] = useState(true);
  const [availableUmpires, setAvailableUmpires] = useState(null);
  const [selectedUmpiresFromTimeSlot, setSelectedUmpiresFromTimeSlot] =
    useState(null);
  const [firstStaffLoading, setfirstStaffLoading] = useState(true);
  const [firstRosterLoading, setfirstRosterLoading] = useState(true);
  const [topBarLoader, settopBarLoader] = useState(false);
  const [callAPI, setcallAPI] = useState(true);
  const [timeSlot, settimeSlot] = useState(null);
  const [currentTarget, setcurrentTarget] = useState(null);
  const [currentCourtID, setcurrentCourtID] = useState(null);
  const [timesAvail, setTimesAvail] = useState([]);
  const [userDetails, setUserDetails] = useState({
    userID: "",
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    ecName1: "",
    ecNumber1: "",
    ecName2: "",
    ecNumber2: "",
  });

  const [courtGameModal, setCourtGameModal] = useState(false);
  const [courtGameData, setCourtGameData] = useState({
    grade: "",
    home_team: "",
    away_team: "",
    match_id: "",
  });
  const [singleCourtData, setSingleCourtData] = useState({});
  const [courtGameError, setCourtGameError] = useState({
    grade_error: "",
    home_team_error: "",
    away_team_error: "",
  });
  const [editHistory, setEditHistory] = useState([]);
  const [addEditNew, setAddEditFlag] = useState();

  // updateFlagMatch states
  const [updateFlagModal, setUpdateFlagModal] = useState(false);
  const [updateFlagText, setUpdateFlagText] = useState("");
  const [updateFlagGridData, setUpdateFlagGridData] = useState({});
  const [updateFlagTextErr, setUpdateFlagTextErr] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  // removeFlagMatch states
  const [removeFlagModal, setRemoveFlagModal] = useState(false);
  const [removeFlagGridData, setRemoveFlagGridData] = useState({});
  const [removeLoading, setRemoveLoading] = useState(false);

  // Re-Release states
  const [reReleaseModal, setReReleaseModal] = useState(false);
  const [dropdownData, setDropdownData] = useState([]);
  const [userValue, setUserValue] = useState([]);

  // Umpire Notes
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [umpireNote, setUmpireNote] = useState("");
  const [umpireNoteError, setUmpireNoteError] = useState({
    umpire_select_error: "",
    note_error: "",
  });
  const [umpireNoteModalData, setUmpireNoteModalData] = useState(null);
  const [noteUmpireList, setNoteUmpireList] = useState([]);
  const [selectedUmpireForNote, setSelectedUmpireForNote] = useState("");

  // Additional Roles
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [umpireRole, setUmpireRole] = useState("");
  const [umpireRoleError, setUmpireRoleError] = useState({
    umpire_select_error: "",
    role_error: "",
  });
  const [umpireRoleData, setUmpireRoleData] = useState(null);
  const [roleUmpireList, setRoleUmpireList] = useState([]);
  const [selectedUmpireForRole, setSelectedUmpireForRole] = useState("");
  const [showRoleToggle, setShowRoleToggle] = useState(false);
  const [toggleRole, setToggleRole] = useState(false);
  const [roleToggleLoading, setRoleToggleLoading] = useState(false);
  const [tempToggleRole, setTempToggleRole] = useState(false);

  // Multiple events
  const [showEventModal, setShowEventModal] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedEventName, setSelectedEventName] = useState("");
  const [roundName, setRoundName] = useState("");
  const [eventInfo, setEventInfo] = useState(null);

  const [calendarDate, setCalendarDate] = useState("");

  const [eventError, setEventError] = useState("");

  const { courtName, mainTableArray, supervisor_flag } = roster.result.data;
  const { rosterDataLoading, totalPages } = roster;
  const { staffListLoading } = staff;

  useEffect(() => {
    if (!firstStaffLoading && !firstRosterLoading) {
      if (staffListLoading || rosterDataLoading) {
        settopBarLoader(true);
      } else {
        settopBarLoader(false);
      }
    }
  }, [
    firstRosterLoading,
    firstStaffLoading,
    rosterDataLoading,
    staffListLoading,
  ]);

  useEffect(() => {
    if (isFilterChange === true) {
      let avail = "1";
      let notAvail = "2";
      let noAvail = "3";
      let alloc = "";

      if (!available) avail = "";
      if (!noAvailable) notAvail = "";
      if (!notAvailable) noAvail = "";
      if (allocated) alloc = "4";

      const allTypes = avail + notAvail + noAvail + alloc;

      const data = function format(s) {
        return s.toString().replace(/\d{1}(?=.)/g, "$&,");
      };

      setAllTypes(data(allTypes));
      getStaffList(assocId, calId, userId, data(allTypes)).then((res) => {
        if (res.data.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        }
      });
      setIsFilterChange(false);
    }
  }, [isFilterChange]);

  const toggleNotification = (e) => {
    setShowFilter(false);
    setopenDatePicker(false);
    setShowNotification(!showNotification);
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  };

  const toggleFilterOptions = (e) => {
    setopenDatePicker(false);
    setShowNotification(true);
    setShowFilter(!showFilter);
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  };

  const toggleCalenderPicker = (e) => {
    setopenDatePicker(!openDatePicker);
    setShowFilter(false);
    setShowNotification(true);
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  };

  function selectDate(date, { selected, disabled }) {
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
    setSelectedEvent("");

    const key = date;

    var result = staff.dates.filter((obj) => {
      const rd = new Date(obj.datestamp);

      return rd.toDateString() === key.toDateString();
    });

    if (result.length > 0) {
      const res = result[0];
      const calId = res.calID;
      const type = "1,2,3";

      setcalId(calId);

      const { dates } = staff;

      const multipleEvent = dates.find(
        (event) => event.calID == calId && event.multiple == 1
      );

      const submitDataToEvents = {
        assocID: assocId,
        manager_id: userId,
        calID: calId,
      };

      getMultipleEvents(submitDataToEvents).then((res) => {
        if (res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        }
        if (res?.data?.success == 1 && res.data?.result?.length > 0) {
          setEvents(
            res.data.result.map((eventData) => ({
              value: eventData.calID,
              label: eventData.event_name,
              round: eventData?.round_name,
              additional_role: eventData?.additional_role,
              calID: eventData?.calID,
            }))
          );

          if (res.data?.result?.length > 1) {
            setShowEventModal(true);
            setCalendarDate(dateFormat(key, "d mmm yyyy, dddd"));
          } else {
            setSelectedEventName(res?.data?.result[0]?.event_name);
            setRoundName(res?.data?.result[0]?.round_name);
            setSelectedDate(dateFormat(key, "d mmm yyyy, dddd"));
            setShowRoleToggle(additionalRoleFlag == "1" ? true : false);
            setToggleRole(
              res.data?.result[0]?.additional_role == "1" ? true : false
            );
            setcalId(res.data?.result[0]?.calID);

            setEventInfo({
              event: res?.data?.result[0]?.event_name,
              round: res?.data?.result[0]?.round_name,
            });

            getStaffList(assocId, calId, userId, type).then((res) => {
              if (res.data.success === "AuthFailed") {
                Swal.fire({
                  title: "Auth Fail",
                  text: "please login",
                  icon: "error",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  focusConfirm: false,
                  preConfirm: () => {
                    window.open(process.env.REACT_APP_REDIRECT_URL);
                    return false; // Prevent confirmed
                  },
                });
              }
              setfirstStaffLoading(false);
            });

            getCombinedData(assocId, calId, currentPage, userId).then((res) => {
              if (res.data.success === "AuthFailed") {
                Swal.fire({
                  title: "Auth Fail",
                  text: "please login",
                  icon: "error",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  focusConfirm: false,
                  preConfirm: () => {
                    window.open(process.env.REACT_APP_REDIRECT_URL);
                    return false; // Prevent confirmed
                  },
                });
              } else {
                setfirstRosterLoading(false);
              }
            });
          }
        }
      });

      setopenDatePicker(!openDatePicker);
      setAvailable(true);
      setNoAvailable(true);
      setNotAvailable(true);
    } else {
      toast.error("No records found for this date");
    }
  }

  const onSelectStaffName = (supervisor) => {
    const avail_time = supervisor.times_avail.split(",");
    setAvailableUmpires(null);
    settimeSlot(null);
    setSelectedUmpiresFromTimeSlot(null);
    // const name = supervisor.givenName + " " + supervisor.lastName[0];
    const name = supervisor.givenName + " " + supervisor.lastName;
    if (selectedStaffName === name) {
      setSelectedStaffName("");
      setCurrentName(false);
      setUserData(null);
      setShowNotification(true);
      setIsLowOpacity(false);
      setTimesAvail([]);
      setNotificationData(null);
    } else {
      setTimesAvail(avail_time);
      setSelectedStaffName(name);
      setCurrentName(true);
      setShowNotification(false);
      setUserData(supervisor);
      setIsLowOpacity(true);

      setNotificationData({
        adminNotes: supervisor.admin_notes,
        comments: supervisor.comments,
        allocation: supervisor.rosterData,
      });
    }
    setShowFilter(false);
    setopenDatePicker(false);
  };

  const handleFilterChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    if (name === "available") {
      setAvailable(checked);
    }

    if (name === "noAvailable") {
      setNoAvailable(checked);
    }

    if (name === "notAvailable") {
      setNotAvailable(checked);
    }

    if (name === "allocated") {
      setAllocated(checked);
    }

    setIsFilterChange(true);
  };

  const handleSupervisorAndMentorsFilter = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    if (e.target.name === "supervisors") {
      if (checked === true) {
        setsupervisors(true);

        setTimeout(() => {
          setShowSupervisors(true);
        }, 800);
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "By removing supervisors, any allocated supervisors will be unallocated. This cannot be reversed. Do you wish to proceed?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          denyButtonText: "#d33",
          confirmButtonText: "Yes, remove it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const mentorData = new FormData();

            mentorData.append("action", "removeSuperVisorFromEntireAllocation");
            mentorData.append("calID", calId);

            updateUserDetails(userId, mentorData)
              .then((res) => {
                if (res.data.success === "AuthFailed") {
                  Swal.fire({
                    title: "Auth Fail",
                    text: "please login",
                    icon: "error",
                    confirmButtonText: "Ok",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    focusConfirm: false,
                    preConfirm: () => {
                      window.open(process.env.REACT_APP_REDIRECT_URL);
                      return false; // Prevent confirmed
                    },
                  });
                } else if (res.data.success === 1) {
                  setCanUndo(false);
                  setShowFilter(false);
                  getStaffList(assocId, calId, userId, allTypes);

                  getCombinedData(assocId, calId, currentPage, userId).then(
                    (result) => {
                      if (result.data.success === "AuthFailed") {
                        Swal.fire({
                          title: "Auth Fail",
                          text: "please login",
                          icon: "error",
                          confirmButtonText: "Ok",
                          allowOutsideClick: false,
                          allowEscapeKey: false,
                          focusConfirm: false,
                          preConfirm: () => {
                            window.open(process.env.REACT_APP_REDIRECT_URL);
                            return false; // Prevent confirmed
                          },
                        });
                      } else {
                        Swal.fire("", res.data.msg, "success");
                        setsupervisors(false);
                        setShowSupervisors(false);
                      }
                    }
                  );
                } else {
                  toast.error("Oops! Something went wrong. Please try again.");
                }
              })
              .catch((err) => {
                toast.error("Oops! Something went wrong. Please try again.");
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            setShowFilter(false);
          }
        });
      }
    }

    if (e.target.name === "unallocated") {
      if (checked) {
        setUnallocated(true);
      } else {
        setUnallocated(false);
      }
    }
    if (e.target.name === "mentors") {
      if (checked) {
        setMentors(true);
        setFullWidth(false);
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "By removing mentors, any allocated mentors will be unallocated. This cannot be reversed. Do you wish to proceed?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          denyButtonText: "#d33",
          confirmButtonText: "Yes, remove it!",
        })
          .then((result) => {
            if (result.isConfirmed) {
              const mentorData = new FormData();

              mentorData.append("action", "removeMentorFromEntireAllocation");
              mentorData.append("assocID", assocId);
              mentorData.append("calID", calId);

              updateUserDetails(userId, mentorData)
                .then((res) => {
                  if (res.data.success === "AuthFailed") {
                    Swal.fire({
                      title: "Auth Fail",
                      text: "please login",
                      icon: "error",
                      confirmButtonText: "Ok",
                      allowOutsideClick: false,
                      allowEscapeKey: false,
                      focusConfirm: false,
                      preConfirm: () => {
                        window.open(process.env.REACT_APP_REDIRECT_URL);
                        return false; // Prevent confirmed
                      },
                    });
                  } else if (res.data.success === 1) {
                    setCanUndo(false);
                    setShowFilter(false);

                    getStaffList(assocId, calId, userId, allTypes);

                    getCombinedData(assocId, calId, currentPage, userId).then(
                      (result) => {
                        if (result.data.success === "AuthFailed") {
                          Swal.fire({
                            title: "Auth Fail",
                            text: "please login",
                            icon: "error",
                            confirmButtonText: "Ok",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            focusConfirm: false,
                            preConfirm: () => {
                              window.open(process.env.REACT_APP_REDIRECT_URL);
                              return false; // Prevent confirmed
                            },
                          });
                        } else {
                          Swal.fire("", res.data.msg, "success");
                          setMentors(false);
                          setFullWidth(true);
                        }
                      }
                    );
                  } else {
                    toast.error(
                      "Oops! Something went wrong. Please try again."
                    );
                  }
                })
                .catch((err) => {
                  toast.error("Oops! Something went wrong. Please try again.");
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              setShowFilter(false);
              setFullWidth(false);
            }
          })
          .catch((res) => {});
      }
    }
  };

  const onNextPageClick = async (e) => {
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);

    const { totalPages } = roster.result;

    if (currentPage < totalPages) {
      const page = currentPage + 1;

      setCurrentPage(page);
      const getcombinedDataRes = await getCombinedData(
        assocId,
        calId,
        page,
        userId
      );
      if (getcombinedDataRes?.data?.success === "AuthFailed") {
        Swal.fire({
          title: "Auth Fail",
          text: "please login",
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          focusConfirm: false,
          preConfirm: () => {
            window.open(process.env.REACT_APP_REDIRECT_URL);
            return false; // Prevent confirmed
          },
        });
      }
    } else {
      toast.info("You are already on the last page.");
    }
  };

  const onPreviousPageClick = (e) => {
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);

    if (currentPage > 1) {
      const page = currentPage - 1;

      setCurrentPage(page);
      getCombinedData(assocId, calId, page, userId);
    } else {
      toast.info("You are already on the first page.");
    }
  };

  function collect(collectedData) {
    const { data, game } = collectedData.data;

    if (game === false) {
      setUserData(data);
      setAdminNotes(data.admin_notes);
      setUserComment(data.comments);
    } else {
      setGameData(data);
    }

    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  }

  const handleMenuClick = async (e, data) => {
    const { type, status, change, confirm } = data;

    if (type === "1") {
      setMenuType("1");
      setIsModalVisible(true);
    } else if (type === "2") {
      setMenuType("2");
      setIsModalVisible(true);
    } else if (type === "3") {
      setMenuType("3");
      const getUpdateUserRes = await getUserDetails(
        assocId,
        userData.userID,
        userId
      );
      if (getUpdateUserRes?.data?.success === "AuthFailed") {
        Swal.fire({
          title: "Auth Fail",
          text: "please login",
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          focusConfirm: false,
          preConfirm: () => {
            window.open(process.env.REACT_APP_REDIRECT_URL);
            return false; // Prevent confirmed
          },
        });
      } else {
        setIsModalVisible(true);
      }
      // console.log("🚀 getUpdateUserRes:", getUpdateUserRes)
    }
    if (change) {
      const updateObj = {
        calID: calId,
        assocID: assocId,
        userID: userData.userID,
        action: "changeAvailability",
        manager_id: userId,
      };

      if (status === 0) {
        updateObj.status = status;
      } else if (status === 1) {
        updateObj.status = status;
      } else if (status === 2) {
        updateObj.status = status;
      }

      updateAllocationData(updateObj)
        .then((res) => {
          if (res.data && res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res.data.success === 1) {
            getStaffList(assocId, calId, userId, allTypes);

            getCombinedData(assocId, calId, currentPage, userId);
          } else {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        })
        .catch((err) => {
          toast.error("Oops! Something went wrong. Please try again.");
        });
    }
  };

  const changeConfirmationStatus = (e, data) => {
    const { confirm } = data;

    let formData = new FormData();

    formData.append("action", "confirmAvailability");
    formData.append("assocID", assocId);
    formData.append("calID", calId);
    formData.append("userID", userData.userID);
    formData.append("availability", confirm);

    updateUserDetails(userId, formData)
      .then((res) => {
        if (res.data.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else {
          getStaffList(assocId, calId, userId, allTypes).then((res) => {
            toast.success(res.data.msg);
          });

          getCombinedData(assocId, calId, currentPage, userId);
        }
      })
      .catch((err) => {
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };
  function collectGameData(gameData) {
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
    setGameData(gameData);
  }

  const handleGameMenu = (e, data) => {
    const gameObj = {
      action: "updateGameCourtAllocation",
      assocID: assocId,
      calID: calId,
      timeID: gameData.timeID,
      courtID: gameData.courtID,
      manager_id: userId,
    };

    if (data.game === "false") {
      Swal.fire({
        title: "Are you sure?",
        text: "This will clear all staff members from the grid and cannot be reversed. Do you wish to proceed?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        denyButtonText: "#d33",
        confirmButtonText: "Yes, clear it!",
      }).then((result) => {
        if (result.isConfirmed) {
          updateAllocationData(gameObj)
            .then((res) => {
              if (res.data && res?.data?.success === "AuthFailed") {
                Swal.fire({
                  title: "Auth Fail",
                  text: "please login",
                  icon: "error",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  focusConfirm: false,
                  preConfirm: () => {
                    window.open(process.env.REACT_APP_REDIRECT_URL);
                    return false; // Prevent confirmed
                  },
                });
              } else if (res.data.success === 1) {
                getCombinedData(assocId, calId, currentPage, userId).then(
                  (result) => {
                    toast.success(res.data.msg);
                  }
                );
              } else {
                toast.error("Oops! Something went wrong. Please try again.");
              }
            })
            .catch((err) => {
              toast.error("Oops! Something went wrong. Please try again.");
            });
        }
      });
    } else {
      updateAllocationData(gameObj)
        .then((res) => {
          if (res.data && res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res.data.success === 1) {
            getCombinedData(assocId, calId, currentPage, userId).then(
              (result) => {
                toast.success(res.data.msg);
              }
            );
          } else {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        })
        .catch((err) => {
          toast.error("Oops! Something went wrong. Please try again.");
        });
    }
  };

  const handleFlagMatch = (e, data) => {
    e.preventDefault();
    setUpdateFlagModal(true);
    setUpdateFlagGridData(data);
  };

  const handleUpdateFlagModalCancel = () => {
    setUpdateFlagModal(false);
    setUpdateFlagText("");
    setUpdateFlagGridData({});
    setUpdateFlagTextErr("");
  };

  const handleUpdateFlagModalSubmit = () => {
    let textErr = "";

    textErr = checkEmptyValidation(updateFlagText, "reason");

    if (textErr && textErr !== "") {
      setUpdateFlagTextErr(textErr);
    } else {
      setUpdateFlagTextErr("");
      setUpdateLoading(true);

      let timeID = updateFlagGridData.timeID;
      let calID = calId;
      let courtID = updateFlagGridData.courtID;
      let assocID = assocId;
      let reason = updateFlagText;
      let manager_id = userId;

      updateFlagMatch(timeID, calID, courtID, assocID, reason, manager_id)
        .then((res) => {
          setUpdateLoading(false);
          if (res.data && res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res?.data?.success === 1) {
            toast.success(res?.data?.msg);
            getCombinedData(assocId, calId, currentPage, userId).then((res) => {
              setfirstRosterLoading(false);
            });
            setUpdateFlagModal(false);
            setUpdateFlagText("");
            setUpdateFlagGridData({});
            setUpdateFlagTextErr("");
          } else {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("Oops! Something went wrong. Please try again.");
        });
    }
  };

  const handleRemoveFlagMatch = (e, data) => {
    e.preventDefault();
    setRemoveFlagModal(true);
    setRemoveFlagGridData(data);
  };

  const handleCancelFlagModalCancel = () => {
    setRemoveFlagModal(false);
    setRemoveFlagGridData({});
  };

  const handleCancelFlagModalSubmit = () => {
    let timeID = removeFlagGridData.timeID;
    let calID = calId;
    let courtID = removeFlagGridData.courtID;
    let assocID = assocId;
    let manager_id = userId;

    setRemoveLoading(true);
    removeFlagMatch(timeID, calID, courtID, assocID, manager_id)
      .then((res) => {
        if (res.data && res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else if (res?.data?.success === 1) {
          setRemoveLoading(false);
          toast.success(res?.data?.msg);
          getCombinedData(assocId, calId, currentPage, userId).then((res) => {
            setfirstRosterLoading(false);
          });
          setRemoveFlagModal(false);
          setRemoveFlagGridData({});
        } else {
          toast.error("Oops! Something went wrong. Please try again.");
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const updateUser = () => {
    const {
      userID,
      firstName,
      lastName,
      email,
      mobile,
      ecNumber1,
      ecNumber2,
      ecName1,
      ecName2,
    } = userDetails;
    setLoading(true);

    let formData = new FormData();

    formData.append("action", "editUserDetails");
    formData.append("userID", userID);
    formData.append("givenName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("emergency_name_1", ecName1);
    formData.append("emergency_phone_1", ecNumber1);
    formData.append("emergency_name_2", ecName2);
    formData.append("emergency_phone_2", ecNumber2);

    updateUserDetails(userId, formData)
      .then((res) => {
        if (res.data.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else {
          setLoading(false);
          setCurrentName(false);
          setSelectedStaffName("");
          setShowNotification(true);

          getStaffList(assocId, calId, userId, allTypes).then((result) => {
            toast.success(res.data.msg);
          });
        }

        setIsModalVisible(false);
      })
      .catch((err) => {
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleOk = () => {
    let updateObj = {
      calID: calId,
      assocID: assocId,
      commentUserID: userData.userID,
      manager_id: userId,
    };

    if (menuType === "1") {
      updateObj.action = "editAdminNote";
      updateObj.comment = adminNotes;
      setLoading(true);
    }
    if (menuType === "2") {
      updateObj.action = "editUserComment";
      updateObj.comment = userComment;
      setLoading(true);
    }

    updateAllocationData(updateObj)
      .then((res) => {
        if (res.data && res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else {
          setLoading(false);
          setAdminNotes("");
          setUserComment("");
          setIsModalVisible(false);

          if (res.data.success === 1) {
            getStaffList(assocId, calId, userId, allTypes).then((result) => {
              toast.success(res.data.msg);
            });

            setShowNotification(true);
            setSelectedStaffName("");
            setCurrentName(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);

        toast.error("Oops! Something went wrong. Please try again.");
      });
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  };

  const handleCancel = () => {
    setAdminNotes("");
    setUserComment("");
    setIsModalVisible(false);
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
    setCourtGameData({
      grade: "",
      home_team: "",
      away_team: "",
      match_id: "",
    });
    setCourtGameModal(false);
    setCourtGameError({
      grade_error: "",
      home_team_error: "",
      away_team_error: "",
    });
    setEditHistory([]);
  };

  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "adminNotes") {
      setAdminNotes(value);
    } else if (name === "userComment") {
      setUserComment(value);
    }
  };

  const handleUserInputChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const allocateUserToGrid = (data, title) => {
    setcallAPI(false);
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);

    if (userData !== null) {
      const updateObj = {
        calID: calId,
        assocID: assocId,
        userID: userData.userID,
        timeID: data.timeID,
        manager_id: userId,
      };

      updateObj.action = "checkAlreadyExist";

      updateAllocationData(updateObj).then((res) => {
        const result = res.data.result;

        if (res.data && res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else if (result.flag === 0) {
          const umpAction = "allocateUserToGrid";
          const mentAction = "allocateMentorToGrid";
          const allocationObj = {
            calID: calId,
            assocID: assocId,
            userID: userData.userID,
            timeID: data.timeID,
            courtID: data.courtID,
            manager_id: userId,
          };

          if (title === "umpA") {
            allocationObj.action = umpAction;
            allocationObj.half = "1";
          } else if (title === "umpB") {
            allocationObj.action = umpAction;
            allocationObj.half = "2";
          } else if (title === "mentorA") {
            allocationObj.action = mentAction;
            allocationObj.half = "1";
          } else if (title === "mentorB") {
            allocationObj.action = mentAction;
            allocationObj.half = "2";
          }

          updateAllocationData(allocationObj)
            .then((res) => {
              if (res.data && res?.data?.success === "AuthFailed") {
                Swal.fire({
                  title: "Auth Fail",
                  text: "please login",
                  icon: "error",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  focusConfirm: false,
                  preConfirm: () => {
                    window.open(process.env.REACT_APP_REDIRECT_URL);
                    return false; // Prevent confirmed
                  },
                });
              } else if (res.data.success === 1) {
                setCanUndo(true);

                getStaffList(assocId, calId, userId, allTypes);

                getCombinedData(assocId, calId, currentPage, userId).then(
                  (res) => {
                    setcallAPI(true);
                  }
                );
              } else {
                setcallAPI(true);
                toast.error("Oops! Something went wrong. Please try again.");
              }
            })
            .catch((err) => {
              setcallAPI(true);

              toast.error("Oops! Something went wrong. Please try again.");
            });
        } else {
          setcallAPI(true);

          toast.error(
            selectedStaffName + " is already in the timeslot " + data.timeSlot
          );
        }
      });
    } else {
      setcallAPI(true);

      toast.error("Please select any name from the list");
    }
  };

  const onTimeSlotClick = (data) => {
    setSelectedStaffName("");
    setTimesAvail([]);
    setCurrentName(false);
    if (timeSlot === null || timeSlot !== data.timeSlot) {
      settimeSlot(data.timeSlot);
      let availableName = new Array();
      let selectedNames = new Array();

      data.subData.forEach((e) => {
        if (e.umpAavailablity === "2") {
          availableName.push(e.umpAName);
        }
      });

      data.subData.forEach((e) => {
        if (e.umpBavailablity === "2") {
          availableName.push(e.umpBName);
        }
      });
      data.subData.forEach((e) => {
        if (e.mentorAavailablity === "2") {
          availableName.push(e.mentorAName);
        }
      });
      data.subData.forEach((e) => {
        if (e.mentorBavailablity === "2") {
          availableName.push(e.mentorBName);
        }
      });
      data.supervisorList.forEach((e) => {
        if (e.superVisorAavailablity === "2") {
          const name = e.givenName + " " + e.lastName[0];
          availableName.push(name);
        }
      });

      // Show in Green If available
      const timeIDIndex = data.timeID - 1;

      if (staff.staffList?.length > 0) {
        staff.staffList.forEach((e) => {
          const avail_time = e.times_avail.split(",");
          if (
            avail_time.includes(timeIDIndex.toString()) &&
            e.has_shift == "0"
          ) {
            selectedNames.push(e.givenName + " " + e.lastName);
          }
        });
      }

      setSelectedUmpiresFromTimeSlot([...selectedNames]);
      setAvailableUmpires([...availableName]);
    } else {
      settimeSlot(null);
      setAvailableUmpires(null);
      setSelectedUmpiresFromTimeSlot(null);
    }
  };
  const removeUserFromGrid = (data, title) => {
    setcallAPI(false);
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);

    const umpAction = "removeFromGrid";
    const mentorAction = "removeMentorFromGrid";

    const removeUserObj = {
      calID: calId,
      assocID: assocId,
      timeID: data.timeID,
      courtID: data.courtID,
      manager_id: userId,
    };

    if (title === "umpA") {
      removeUserObj.action = umpAction;
      removeUserObj.half = "1";
    } else if (title === "umpB") {
      removeUserObj.action = umpAction;
      removeUserObj.half = "2";
    } else if (title === "mentorA") {
      removeUserObj.action = mentorAction;
      removeUserObj.half = "1";
    } else if (title === "mentorB") {
      removeUserObj.action = mentorAction;
      removeUserObj.half = "2";
    }

    updateAllocationData(removeUserObj)
      .then((res) => {
        if (res.data && res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else if (res.data.success === 1) {
          setCanUndo(true);

          getStaffList(assocId, calId, userId, allTypes);

          getCombinedData(assocId, calId, currentPage, userId).then((res) => {
            setcallAPI(true);
          });
        } else {
          setcallAPI(true);
          toast.error("Oops! Something went wrong. Please try again.");
        }
      })
      .catch((err) => {
        setcallAPI(true);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };
  const undoAllocations = () => {
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);

    if (canUndo) {
      const undoAllocationObj = {
        action: "undoAllocation",
        calID: calId,
        assocID: assocId,
        manager_id: userId,
      };

      updateAllocationData(undoAllocationObj)
        .then((res) => {
          if (res.data && res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res.data.success === 1) {
            getCombinedData(assocId, calId, currentPage, userId).then(
              (result) => {
                setCanUndo(false);
                toast.success(res.data.msg);
              }
            );
          }
        })
        .catch((err) => {
          toast.error("Oops! Something went wrong. Please try again.");
        });
    } else {
      toast.info("You can undo your last action only.");
    }
  };

  const clearAllocations = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will clear the entire grid and cannot be reversed. Do you wish to proceed?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      denyButtonText: "#d33",
      confirmButtonText: "Yes, clear it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const clearAllocationObj = {
          action: "clearAllocation",
          calID: calId,
          assocID: assocId,
          manager_id: userId,
        };

        updateAllocationData(clearAllocationObj)
          .then((res) => {
            if (res.data && res?.data?.success === "AuthFailed") {
              Swal.fire({
                title: "Auth Fail",
                text: "please login",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                preConfirm: () => {
                  window.open(process.env.REACT_APP_REDIRECT_URL);
                  return false; // Prevent confirmed
                },
              });
            } else if (res.data.success === 1) {
              getStaffList(assocId, calId, userId, allTypes);

              getCombinedData(assocId, calId, currentPage, userId).then(
                (result) => {
                  Swal.fire("", res.data.msg, "success");
                }
              );
            }
          })
          .catch((err) => {
            toast.error("Oops! Something went wrong. Please try again.");
          });
      }
    });
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  };

  const allocateSupervisorToGrid = (e, data) => {
    if (e.target.id === "allocate-s") {
      if (userData !== null) {
        setcallAPI(false);

        const updateObj = {
          calID: calId,
          assocID: assocId,
          userID: userData.userID,
          timeID: data.timeID,
          action: "checkAlreadyExist",
          manager_id: userId,
        };

        updateAllocationData(updateObj)
          .then((res) => {
            const result = res.data.result;
            if (res.data && res?.data?.success === "AuthFailed") {
              Swal.fire({
                title: "Auth Fail",
                text: "please login",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                preConfirm: () => {
                  window.open(process.env.REACT_APP_REDIRECT_URL);
                  return false; // Prevent confirmed
                },
              });
            } else if (result.flag === 0) {
              const supervisorObj = {
                calID: calId,
                userID: userData.userID,
                timeID: data.timeID,
                assocID: assocId,
                action: "allocateSuperVisor",
                manager_id: userId,
              };

              updateAllocationData(supervisorObj)
                .then((res) => {
                  if (res.data.success === 1) {
                    setCanUndo(true);

                    getStaffList(assocId, calId, userId, allTypes);

                    getCombinedData(assocId, calId, currentPage, userId).then(
                      (res) => {
                        setcallAPI(true);
                      }
                    );
                  } else {
                    setcallAPI(true);
                    toast.error(
                      "Oops! Something went wrong. Please try again."
                    );
                  }
                })
                .catch((err) => {
                  setcallAPI(true);
                  toast.error("Oops! Something went wrong. Please try again.");
                });
            } else {
              setcallAPI(true);
              toast.error(
                selectedStaffName +
                  " is already in the timeslot " +
                  data.timeSlot
              );
            }
          })
          .catch((err) => {
            setcallAPI(true);
            toast.error("Oops! Something went wrong. Please try again.");
          });
      } else {
        toast.error("Please select any name from the list");
      }
      setAvailableUmpires(null);
      setSelectedUmpiresFromTimeSlot(null);
    }
  };

  const removeSupervisorFromGrid = (e, supervisor, data) => {
    if (e.target.id === "remove-s") {
      const supervisorObj = {
        calID: calId,
        timeID: data.timeID,
        userID: supervisor.userID,
        assocID: assocId,
        action: "removeSupervisor",
        manager_id: userId,
      };

      updateAllocationData(supervisorObj)
        .then((res) => {
          if (res.data && res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res.data.success === 1) {
            setCanUndo(true);

            getStaffList(assocId, calId, userId, allTypes);
            getCombinedData(assocId, calId, currentPage, userId);
          }
        })
        .catch((err) => {
          toast.error("Oops! Something went wrong. Please try again.");
        });
      setAvailableUmpires(null);
      setSelectedUmpiresFromTimeSlot(null);
    }
  };

  const onReleaseRosterClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will release roster to umpires and send a notification. Any changes after this, you will need to notify the umpire directly. Do you wish to proceed?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      denyButtonText: "#d33",
      confirmButtonText: "Yes, release it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const releaseObj = {
          action: "releaseRoster",
          assocID: assocId,
          calID: calId,
          userID: userId,
          manager_id: userId,
        };

        updateAllocationData(releaseObj)
          .then(async (res) => {
            if (res.data && res?.data?.success === "AuthFailed") {
              Swal.fire({
                title: "Auth Fail",
                text: "please login",
                icon: "error",
                confirmButtonText: "Ok",
                allowOutsideClick: false,
                allowEscapeKey: false,
                focusConfirm: false,
                preConfirm: () => {
                  window.open(process.env.REACT_APP_REDIRECT_URL);
                  return false; // Prevent confirmed
                },
              });
            } else if (res.data.success === 1) {
              getStaffList(assocId, calId, userId, allTypes);

              getCombinedData(assocId, calId, currentPage, userId).then(
                (result) => {
                  Swal.fire("", res.data.msg, "success");
                }
              );
              const getDatesRes = await getDatesForAllocation(assocId, userId);
              if (getDatesRes?.data?.success === "AuthFailed") {
                Swal.fire({
                  title: "Auth Fail",
                  text: "please login",
                  icon: "error",
                  confirmButtonText: "Ok",
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                  focusConfirm: false,
                  preConfirm: () => {
                    window.open(process.env.REACT_APP_REDIRECT_URL);
                    return false; // Prevent confirmed
                  },
                });
              }
            }
          })
          .catch((err) => {
            toast.error("Oops! Something went wrong. Please try again.");
          });
      }
    });
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  };

  const onReReleaseRosterClick = () => {
    setReReleaseModal(true);
    getAllocatedShiftUsers(assocId, calId, userId)
      .then((res) => {
        let result = res.data?.result?.map((selectData) => {
          return {
            label: `${selectData?.userName}`,
            value: selectData?.userID,
          };
        });
        setDropdownData(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReReleaseCancel = () => {
    setReReleaseModal(false);
    setDropdownData([]);
    setUserValue([]);
  };

  const handleReReleaseSubmit = () => {
    ReReleaseRoasterSend(assocId, calId, userId, userValue)
      .then((res) => {
        toast.success(res?.data?.msg);
        setReReleaseModal(false);
        setDropdownData([]);
        setUserValue([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (data, e) => {
    setUserValue(Array.isArray(data) ? data?.map((x) => x.value) : []);
  };

  const onDragOver = (e, data, target) => {
    setcurrentTarget(target);
    setcurrentCourtID(data.courtID);
    e.preventDefault();

    setShowContextMenu(false);
  };

  const onDragStart = (e, data, target) => {
    setShowContextMenu(false);
    setDragData(data);
    setDragTarget(target);
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  };

  const onDragCustom = (e) => {
    e.preventDefault();
    setShowContextMenu(false);
    setDragData(null);
    setDragTarget("");
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);
  };

  const onDrop = (e, currentData, currentTarget) => {
    setTimeout(() => {
      setShowContextMenu(true);
    }, 1000);

    setcurrentTarget(null);
    setcurrentCourtID(null);
    setAvailableUmpires(null);
    setSelectedUmpiresFromTimeSlot(null);

    if (dragData?.timeID !== null && dragData?.timeID !== undefined) {
      if (dragData.timeID === currentData.timeID) {
        const dt = dragTarget;
        const dd = dragData;
        const ct = currentTarget;
        const cd = currentData;

        const SwapObj = {
          timeID: dd.timeID,
          assocID: assocId,
          calID: calId,
          first: {
            courtID: dd.courtID,
            userID:
              dt === "u1"
                ? dd.umpAID
                : dt === "u2"
                ? dd.umpBID
                : dt === "m1"
                ? dd.mentorAID
                : dt === "m2"
                ? dd.mentorBID
                : "",
            action_for: dt === "u1" || dt === "u2" ? "1" : "2",
            half: dt === "u1" || dt === "m1" ? "1" : "2",
          },
          second: {
            courtID: cd.courtID,
            action_for: ct === "u1" || ct === "u2" ? "1" : "2",
            userID:
              ct === "u1"
                ? cd.umpAID
                : ct === "u2"
                ? cd.umpBID
                : ct === "m1"
                ? cd.mentorAID
                : ct === "m2"
                ? cd.mentorBID
                : "",
            half: ct === "u1" || ct === "m1" ? "1" : "2",
          },
        };

        swapUsers(userId, SwapObj).then((res) => {
          if (res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res.data.success === 1) {
            setCanUndo(true);

            getStaffList(assocId, calId, userId, allTypes);

            getCombinedData(assocId, calId, currentPage, userId);
          } else {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        });
      } else {
        toast.error("You can't swap into another time slot. ");
      }
    }
  };
  const onMenuRowClick = (e) => {
    const target = e.target.id;

    if (target === "main") {
      setShowNotification(true);
      setShowFilter(false);
      setopenDatePicker(false);
      setAvailableUmpires(null);
      setSelectedUmpiresFromTimeSlot(null);
    }
  };

  const onCloseDateClick = (e) => {
    const fd = new FormData();

    fd.append("assocID", assocId);
    fd.append("userID", userId);
    fd.append("calID", calId);

    closeAllocationDate(userId, fd).then(async (res) => {
      if (res?.data?.success === "AuthFailed") {
        Swal.fire({
          title: "Auth Fail",
          text: "please login",
          icon: "error",
          confirmButtonText: "Ok",
          allowOutsideClick: false,
          allowEscapeKey: false,
          focusConfirm: false,
          preConfirm: () => {
            window.open(process.env.REACT_APP_REDIRECT_URL);
            return false; // Prevent confirmed
          },
        });
      } else if (res.data.success === 1) {
        const getDatesRes = await getDatesForAllocation(assocId, userId);
        if (getDatesRes?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        }
        getStaffList(assocId, calId, userId, allTypes);
        getCombinedData(assocId, calId, currentPage, userId).then((result) => {
          toast.success(res.data.msg);
        });
      } else {
        toast.error("Oops! Something went wrong. Please try again.");
      }
    });
  };

  const handleCourtModal = (courtData, wholeData) => {
    axios
      .get(
        Get_Edit_History +
          `assocId=${assocId}&match_id=${courtData?.match_id}&manager_id=${userId}`
      )
      .then((res) => {
        if (res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else {
          setEditHistory(res?.data?.result);
        }
      })
      .catch((err) => console.log(err, "err"));

    setCourtGameModal(true);
    setCourtGameData(courtData);
    setSingleCourtData(wholeData);
    if (courtData) {
      setAddEditFlag(2);
    } else {
      setAddEditFlag(1);
    }
  };

  const handleInputData = (e, type) => {
    if (type === 1) {
      setCourtGameData({
        ...courtGameData,
        grade: e.target.value,
      });
    } else if (type === 2) {
      setCourtGameData({
        ...courtGameData,
        home_team: e.target.value,
      });
    } else if (type === 3) {
      setCourtGameData({
        ...courtGameData,
        away_team: e.target.value,
      });
    }
  };

  // Submit after change
  const handleCourtSubmit = () => {
    let grade_err,
      home_err,
      away_err = "";

    grade_err = checkEmptyValidation(courtGameData?.grade, "Grade");
    home_err = checkEmptyValidation(courtGameData?.home_team, "Home Team");
    away_err = checkEmptyValidation(courtGameData?.away_team, "Away Team");

    if (grade_err || home_err || away_err) {
      setCourtGameError({
        ...courtGameData,
        grade_error: grade_err,
        home_team_error: home_err,
        away_team_error: away_err,
      });
    } else if (
      courtGameData?.grade?.length > 50 ||
      courtGameData?.home_team?.length > 50 ||
      courtGameData?.away_team?.length > 50
    ) {
      if (courtGameData?.grade?.length > 50) {
        setCourtGameError({
          ...courtGameData,
          grade_error: "Allowed maximum 50 characters only",
        });
      } else if (courtGameData?.home_team?.length > 50) {
        setCourtGameError({
          ...courtGameData,
          home_team_error: "Allowed maximum 50 characters only",
        });
      } else if (courtGameData?.away_team?.length > 50) {
        setCourtGameError({
          ...courtGameData,
          away_team_error: "Allowed maximum 50 characters only",
        });
      }
    } else {
      setCourtGameError({
        grade_error: "",
        home_team_error: "",
        away_team_error: "",
      });

      let time_slot = singleCourtData?.timeSlot;
      let final_time = time_slot.split(" ")[0];

      const fd = new FormData();

      fd.append("time", time_slot);
      fd.append("venue", singleCourtData?.courtID);

      if (singleCourtData?.matchData?.length === 0) {
        fd.append("match_id", "");
      } else {
        fd.append("match_id", singleCourtData?.matchData?.match_id);
      }

      fd.append("grade", courtGameData?.grade);
      fd.append("home_team", courtGameData?.home_team);
      fd.append("away_team", courtGameData?.away_team);

      if (addEditNew === 1) {
        fd.append("addEditFlag", addEditNew);
      } else {
        fd.append("addEditFlag", addEditNew);
      }

      gameLabelChange(assocId, calId, userId, fd)
        .then((res) => {
          if (res.data && res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res?.data?.success === 1) {
            toast.success(res?.data?.msg);
            getCombinedData(assocId, calId, currentPage, userId).then((res) => {
              setfirstRosterLoading(false);
            });
          } else {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("Oops! Something went wrong. Please try again.");
        });
      setCourtGameData({
        grade: "",
        home_team: "",
        away_team: "",
        match_id: "",
      });
      setCourtGameModal(false);
    }
  };

  const info = (data) => {
    Modal.info({
      title: data,
      onOk() {},
    });
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // marginTop: "5px",
      padding: "4px 4px",
      borderRadius: "8px",
      fontSize: "14px",
      border: state.isFocused ? "1px solid rgb(33,150,243);" : "1px solid rey",
      // boxShadow: state.isFocused
      //     ? " 0 0 0 0.15rem rgb(33,150,243);"
      //     : "1px solid rey",
      "&:hover": {
        border: state.isFocused ? "1px solid rgb(33,150,243)" : "1px solid rey",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    menuList: (base) => ({
      ...base,
      outlineColor: "rgb(52, 152, 255)",
      zIndex: 99999999,
      fontSize: "14px",
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "rgb(52, 152, 255, 0.55)",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#000",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "red",
      ":hover": {
        backgroundColor: "red",
        color: "white",
      },
    }),
  };
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input type="checkbox" checked={isSelected} onChange={(e) => {}} />
        {children}
      </components.Option>
    );
  };

  const handleOpenNote = (e, data) => {
    e.preventDefault();
    setShowNoteModal(true);
    if (data) {
      setUmpireNoteModalData(data);
      setNoteUmpireList(
        [
          {
            value: "umpA",
            label: data?.umpAName,
          },
          {
            value: "umpB",
            label: data?.umpBName,
          },
          {
            value: "mentorA",
            label: data?.mentorAName,
          },
          {
            value: "mentorB",
            label: data?.mentorBName,
          },
        ].filter((option) => option.label)
      );
    }
  };

  const handleCancelUmpireNote = () => {
    setShowNoteModal(false);
    setUmpireNote("");
    setUmpireNoteModalData(null);
    setNoteUmpireList([]);
    setSelectedUmpireForNote("");
    setUmpireNoteError({
      note_error: "",
      umpire_select_error: "",
    });
  };

  const handleChangeUmpireNote = (e) => {
    setUmpireNote(e.target.value);
  };

  const handleSelectUmpireForNote = (e) => {
    setSelectedUmpireForNote(e.value);
    switch (e.value) {
      case "umpA":
        setUmpireNote(umpireNoteModalData?.umpANote);
        break;
      case "umpB":
        setUmpireNote(umpireNoteModalData?.umpBNote);
        break;
      case "mentorA":
        setUmpireNote(umpireNoteModalData?.mentorANote);
        break;
      case "mentorB":
        setUmpireNote(umpireNoteModalData?.mentorBNote);
        break;

      default:
        break;
    }
  };

  const handleSubmitUmpireNote = () => {
    let note_err,
      umpire_err = "";

    const trimmedUmpireNote = umpireNote.trim();

    note_err = checkEmptyValidation(trimmedUmpireNote, "Note");
    umpire_err = checkEmptyValidation(selectedUmpireForNote, "Umpire");

    if (note_err || umpire_err) {
      setUmpireNoteError({
        ...umpireNoteError,
        note_error: note_err,
        umpire_select_error: umpire_err,
      });
    } else {
      const submitNoteData = {
        calID: calId,
        assocID: assocId,
        manager_id: userId,
        courtID: umpireNoteModalData.courtID,
        timeID: umpireNoteModalData.timeID,
        note: trimmedUmpireNote,
        flag: selectedUmpireForNote,
      };

      addUmpireNote(submitNoteData)
        .then((res) => {
          if (res.data && res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res?.data?.success === 1) {
            toast.success(res?.data?.msg);
            getCombinedData(assocId, calId, currentPage, userId).then((res) => {
              setfirstRosterLoading(false);
            });
          } else {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("Oops! Something went wrong. Please try again.");
        })
        .finally(() => {
          handleCancelUmpireNote();
        });
    }
  };

  const onEditUmpireNote = (data, note, value) => {
    setShowNoteModal(true);
    if (data) {
      setUmpireNoteModalData(data);
      setNoteUmpireList(
        [
          {
            value: "umpA",
            label: data?.umpAName,
          },
          {
            value: "umpB",
            label: data?.umpBName,
          },
          {
            value: "mentorA",
            label: data?.mentorAName,
          },
          {
            value: "mentorB",
            label: data?.mentorBName,
          },
        ].filter((option) => option.label)
      );
    }
    setSelectedUmpireForNote(value);
    setUmpireNote(note);
  };

  const onToggleAdditionalRoles = (checked) => {
    setRoleToggleLoading(true);

    const additionalRolePayload = {
      manager_id: userId,
      calID: calId,
      value: checked ? "1" : "0",
    };

    updateAdditionalRoleFlag(additionalRolePayload)
      .then((res) => {
        if (res.data && res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else if (res?.data?.success === 1 && res?.data?.updateFlag === 1) {
          toast.success(res?.data?.msg);
          setToggleRole(checked);
        } else if (res?.data?.success === 1 && res?.data?.updateFlag === 0) {
          toast.error(res?.data?.msg);
        } else {
          toast.error("Oops! Something went wrong. Please try again.");
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Oops! Something went wrong. Please try again.");
      })
      .finally(() => {
        setRoleToggleLoading(false);
      });
  };

  const handleOpenRole = (e, data) => {
    e.preventDefault();
    setShowRoleModal(true);

    setUmpireRoleData(data);

    if (staff?.staffList.length > 0) {
      // Not selected for this time slot
      const filteredUsers = staff.staffList.filter(
        (val) =>
          val.userID !== data.umpAID &&
          val.userID !== data.umpBID &&
          val.userID !== data.mentorAID &&
          val.userID !== data.mentorBID
      );

      setRoleUmpireList(
        filteredUsers.map((obj) => {
          return {
            value: obj.userID,
            label: `${obj.givenName} ${obj.lastName}`,
          };
        })
      );
    }
  };

  const handleSelectUmpireForRole = (e) => {
    setSelectedUmpireForRole(e.value);

    const hasRole = umpireRoleData?.additionalRoles.find(
      (role) => role.roleUserId == e.value
    );

    if (hasRole) {
      setUmpireRole(hasRole.roleName);
    } else {
      setUmpireRole("");
    }
  };

  const handleChangeUmpireRole = (e) => {
    setUmpireRole(e.target.value);
  };

  const handleCancelUmpireRole = () => {
    setShowRoleModal(false);
    setUmpireRole("");
    setUmpireRoleData(null);
    setRoleUmpireList([]);
    setSelectedUmpireForRole("");
    setUmpireRoleError({
      role_error: "",
      umpire_select_error: "",
    });
  };

  const handleSubmitUmpireRole = () => {
    let role_err,
      umpire_err = "";

    const trimmedUmpireRole = umpireRole.trim();

    role_err = checkRequiredValidationWithMinMax(
      trimmedUmpireRole,
      "Role ",
      1,
      10
    );
    umpire_err = checkEmptyValidation(selectedUmpireForRole, "Umpire");

    if (role_err || umpire_err) {
      setUmpireRoleError({
        ...umpireRoleError,
        role_error: role_err,
        umpire_select_error: umpire_err,
      });
    } else {
      const submitRoleData = {
        calID: calId,
        assocID: assocId,
        manager_id: userId,
        courtID: umpireRoleData.courtID,
        timeID: umpireRoleData.timeID,
        userID: selectedUmpireForRole,
        roleName: trimmedUmpireRole,
      };

      addAdditionalRole(submitRoleData)
        .then((res) => {
          if (res.data && res?.data?.success === "AuthFailed") {
            Swal.fire({
              title: "Auth Fail",
              text: "please login",
              icon: "error",
              confirmButtonText: "Ok",
              allowOutsideClick: false,
              allowEscapeKey: false,
              focusConfirm: false,
              preConfirm: () => {
                window.open(process.env.REACT_APP_REDIRECT_URL);
                return false; // Prevent confirmed
              },
            });
          } else if (res?.data?.success === 1) {
            toast.success(res?.data?.msg);
            getCombinedData(assocId, calId, currentPage, userId).then((res) => {
              setfirstRosterLoading(false);
            });
            getStaffList(assocId, calId, userId, allTypes);
          } else {
            toast.error("Oops! Something went wrong. Please try again.");
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("Oops! Something went wrong. Please try again.");
        })
        .finally(() => {
          handleCancelUmpireRole();
        });
    }
  };

  const onEditUmpireRole = (data, name, id) => {
    setShowRoleModal(true);

    setUmpireRoleData(data);
    setUmpireRole(name);
    setSelectedUmpireForRole(id);

    if (staff?.staffList.length > 0) {
      // Not selected for this time slot
      const filteredUsers = staff.staffList.filter(
        (val) =>
          val.userID !== data.umpAID &&
          val.userID !== data.umpBID &&
          val.userID !== data.mentorAID &&
          val.userID !== data.mentorBID
      );

      setRoleUmpireList(
        filteredUsers.map((obj) => {
          return {
            value: obj.userID,
            label: `${obj.givenName} ${obj.lastName}`,
          };
        })
      );
    }
  };

  const onRemoveUmpireRole = (data, roleUserId) => {
    const removeRoleData = {
      calID: calId,
      assocID: assocId,
      manager_id: userId,
      courtID: data.courtID,
      timeID: data.timeID,
      userID: roleUserId,
    };

    removeAdditionalRole(removeRoleData)
      .then((res) => {
        if (res.data && res?.data?.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else if (res?.data?.success === 1) {
          toast.success(res?.data?.msg);
          getCombinedData(assocId, calId, currentPage, userId).then((res) => {
            setfirstRosterLoading(false);
          });
          getStaffList(assocId, calId, userId, allTypes);
        } else {
          toast.error("Oops! Something went wrong. Please try again.");
        }
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("Oops! Something went wrong. Please try again.");
      });
  };

  const handleCancelEvents = () => {
    setShowEventModal(false);
    setEventError("");
  };

  const handleSubmitEvents = () => {
    let event_err = "";

    event_err = checkEmptyValidation(selectedEvent, "Event");

    if (event_err) {
      setEventError(event_err);
    } else {
      setShowEventModal(false);
      setSelectedDate(calendarDate);
      setEventError("");
      setEventInfo({
        event: selectedEventName,
        round: roundName,
      });
      setShowRoleToggle(additionalRoleFlag == "1" ? true : false);
      setToggleRole(tempToggleRole);
      const type = "1,2,3";

      getStaffList(assocId, calId, userId, type).then((res) => {
        if (res.data.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        }
        setfirstStaffLoading(false);
      });

      getCombinedData(assocId, calId, currentPage, userId).then((res) => {
        if (res.data.success === "AuthFailed") {
          Swal.fire({
            title: "Auth Fail",
            text: "please login",
            icon: "error",
            confirmButtonText: "Ok",
            allowOutsideClick: false,
            allowEscapeKey: false,
            focusConfirm: false,
            preConfirm: () => {
              window.open(process.env.REACT_APP_REDIRECT_URL);
              return false; // Prevent confirmed
            },
          });
        } else {
          setfirstRosterLoading(false);
        }
      });
    }
  };

  const handleSelectEvents = (e) => {
    setSelectedEvent(e.value);
    setSelectedEventName(e.label);
    setRoundName(e.round);
    setTempToggleRole(e.additional_role == "1" ? true : false);
    setcalId(e.calID);
  };

  return (
    <Fragment>
      {topBarLoader && <TopBarProgress />}

      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        transition={Flip}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="main-section"
        id="main"
        onClick={(e) => onMenuRowClick(e)}
      >
        <div className=" staff-list-section" id="main">
          <div className="roster-logo">
            <div>
              <img
                src={rosterLogo}
                alt="Time Umpire Logo"
                className="logo-img cursor-pointer"
                onClick={() => window.location.reload()}
              />
            </div>
          </div>
          <ul>
            {released !== "1" && staff.staffList.length > 0 ? (
              staff.staffList.map((data, i) => {
                const name = data.givenName + " " + data.lastName;
                return (
                  <ContextMenuTrigger
                    id="menu_type"
                    collect={collect}
                    data={{ data: data, game: false }}
                    key={i}
                  >
                    <li
                      className={classnames(
                        "staff-list",
                        {
                          "low-opacity":
                            currentName && name !== selectedStaffName,
                        },
                        {
                          "highlight-background":
                            availableUmpires !== null &&
                            availableUmpires.includes(name),
                        },
                        {
                          "green-background":
                            selectedUmpiresFromTimeSlot !== null &&
                            selectedUmpiresFromTimeSlot.includes(name),
                        }
                      )}
                      onClick={(e) => onSelectStaffName(data)}
                    >
                      <span>
                        {data.givenName +
                          " " +
                          data.lastName +
                          " " +
                          "(" +
                          data.total_count +
                          ")"}
                      </span>
                      <span
                        className={classnames(
                          {
                            "status-available": data.flag === 1,
                          },
                          {
                            "status-no-available": data.flag === 3,
                          },
                          {
                            "status-not-available": data.flag === 2,
                          }
                        )}
                      />
                    </li>
                  </ContextMenuTrigger>
                );
              })
            ) : staff.staffListLoading === true ? (
              <li className="text-center pt-3">
                <StaffListLoader />{" "}
              </li>
            ) : (
              <li className="staff-list"> No Records Found</li>
            )}
            <ContextMenu id="menu_type" className="context-menu">
              {userData !== null && userData.flag !== 1 && released !== "3" && (
                <MenuItem
                  className="cursor-pointer"
                  onClick={handleMenuClick}
                  data={{ status: 2, change: true }}
                >
                  Available
                </MenuItem>
              )}
              {userData !== null && userData.flag !== 3 && released !== "3" && (
                <MenuItem
                  className="cursor-pointer"
                  onClick={handleMenuClick}
                  data={{ status: 1, change: true }}
                >
                  Not Available
                </MenuItem>
              )}
              {userData !== null && userData.flag !== 2 && released !== "3" && (
                <MenuItem
                  className="cursor-pointer"
                  onClick={handleMenuClick}
                  data={{ status: 0, change: true }}
                >
                  No Available
                </MenuItem>
              )}

              {released !== null &&
              released !== "1" &&
              released !== "2" &&
              userData !== null &&
              userData.confirmed === "1" ? (
                <MenuItem
                  className="cursor-pointer"
                  onClick={changeConfirmationStatus}
                  data={{ confirm: 0 }}
                >
                  <i className="fas fa-times-circle" />{" "}
                  <span>Set to not confirmed</span>
                </MenuItem>
              ) : released !== null &&
                released !== "1" &&
                released !== "2" &&
                userData !== null &&
                // userData.confirmed === "0" &&
                userData.has_shift === "1" ? (
                <MenuItem
                  className="cursor-pointer"
                  onClick={changeConfirmationStatus}
                  data={{ confirm: 1 }}
                >
                  <i className="fas fa-check-circle"></i>{" "}
                  <span> Set to confirmed</span>
                </MenuItem>
              ) : null}

              <hr className="my-2" />

              <MenuItem
                className="cursor-pointer"
                onClick={handleMenuClick}
                data={{ type: "3" }}
              >
                Update User Details
              </MenuItem>

              <MenuItem
                className="cursor-pointer"
                onClick={handleMenuClick}
                data={{ type: "1" }}
              >
                Admin Notes
              </MenuItem>

              <MenuItem
                className="cursor-pointer"
                onClick={handleMenuClick}
                data={{ type: "2" }}
              >
                User Comment
              </MenuItem>
            </ContextMenu>
          </ul>
          <Modal
            title={
              menuType === "1" && userData !== null
                ? `Update Admin Notes for ${
                    userData.givenName + " " + userData.lastName
                  }`
                : menuType === "2" && userData !== null
                ? `Update User Comment for ${
                    userData.givenName + " " + userData.lastName
                  }`
                : menuType === "3" && userData !== null
                ? `Update User Details for ${
                    userData.givenName + " " + userData.lastName
                  }`
                : null
            }
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={menuType === "3" ? 800 : 500}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={menuType === "3" ? updateUser : handleOk}
              >
                Update
              </Button>,
            ]}
          >
            {menuType === "1" ? (
              <Input.TextArea
                rows={4}
                className="my-2"
                placeholder="Write admin notes here..."
                name="adminNotes"
                onChange={(e) => handleFieldChange(e)}
                value={adminNotes}
              />
            ) : menuType === "2" ? (
              <Input.TextArea
                className="my-2"
                rows={4}
                placeholder="Write user comment here..."
                name="userComment"
                onChange={(e) => handleFieldChange(e)}
                value={userComment}
              />
            ) : menuType === "3" ? (
              <>
                <form>
                  <div className="row">
                    <div className="col-md-6 my-2">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        id="firstName"
                        type="text"
                        name="firstName"
                        className="form-control form-control-sm"
                        placeholder="Enter first name"
                        value={userDetails.firstName}
                        onChange={(e) => handleUserInputChange(e)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        id="lastName"
                        type="text"
                        name="lastName"
                        className="form-control form-control-sm"
                        placeholder="Enter last name"
                        value={userDetails.lastName}
                        onChange={(e) => handleUserInputChange(e)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="email">Email</label>
                      <input
                        id="email"
                        type="text"
                        name="email"
                        className="form-control form-control-sm"
                        placeholder="Enter email id"
                        value={userDetails.email}
                        onChange={(e) => handleUserInputChange(e)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="mobile">Mobile Number</label>
                      <input
                        id="mobile"
                        type="number"
                        name="mobile"
                        className="form-control form-control-sm"
                        placeholder="Enter mobile number"
                        value={userDetails.mobile}
                        onChange={(e) => handleUserInputChange(e)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="ecName1">Emergency Contact Name 1</label>
                      <input
                        id="ecName1"
                        type="text"
                        name="ecName1"
                        className="form-control form-control-sm"
                        placeholder="Enter Contact Name 1"
                        value={userDetails.ecName1}
                        onChange={(e) => handleUserInputChange(e)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="ecNumber1">
                        Emergency Contact Number 1
                      </label>
                      <input
                        id="ecNumber1"
                        type="number"
                        name="ecNumber1"
                        className="form-control form-control-sm"
                        placeholder="Enter Contact Number 1"
                        value={userDetails.ecNumber1}
                        onChange={(e) => handleUserInputChange(e)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="ecName2">Emergency Contact Name 2</label>
                      <input
                        id="ecName2"
                        type="text"
                        name="ecName2"
                        className="form-control form-control-sm"
                        placeholder="Enter Contact Name 2"
                        value={userDetails.ecName2}
                        onChange={(e) => handleUserInputChange(e)}
                      />
                    </div>
                    <div className="col-md-6 my-2">
                      <label htmlFor="ecNumber2">
                        Emergency Contact Number 2
                      </label>
                      <input
                        id="ecNumber2"
                        type="number"
                        name="ecNumber2"
                        className="form-control form-control-sm"
                        placeholder="Enter Contact Number 2"
                        value={userDetails.ecNumber2}
                        onChange={(e) => handleUserInputChange(e)}
                      />
                    </div>
                  </div>
                </form>
              </>
            ) : null}
          </Modal>
        </div>
        <div className="row px-0 mx-0" id="main">
          <div className="menu-section">
            <div className="menu-list" id="main">
              <div className="col-md-12 w-100" id="main">
                {released === "2" ? (
                  <div
                    className="btn-release-roster"
                    onClick={onReleaseRosterClick}
                  >
                    Release Roster
                  </div>
                ) : released === "3" ? (
                  <div
                    className="btn-release-roster"
                    onClick={onReReleaseRosterClick}
                  >
                    Roster Re-Release
                  </div>
                ) : released === "1" || released === null ? null : (
                  <div className="btn-release-roster">Roster Released</div>
                )}
                <div
                  className="inner-part"
                  style={{
                    paddingRight:
                      released !== null ? released === "1" && "172px" : "172px",
                  }}
                >
                  <div
                    className="filter-circle "
                    onClick={(e) => toggleFilterOptions(e)}
                  >
                    <img src={filter} className="icon-filter" alt="arrow" />
                  </div>
                  {showFilter && (
                    <Fragment>
                      <img
                        src={filterArrow}
                        className="filter-arrow"
                        alt="arrow"
                      />

                      <div className="filter-dropdown ">
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="available_id"
                            name="available"
                            defaultChecked={selectedDate !== "" && available}
                            disabled={selectedDate === ""}
                            onChange={(e) => handleFilterChange(e)}
                          />
                          <label
                            className={classnames("form-check-label pt-1", {
                              "disabled-cursor": selectedDate === "",
                            })}
                            htmlFor="available_id"
                          >
                            Available
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="no_available_id"
                            name="noAvailable"
                            defaultChecked={selectedDate !== "" && noAvailable}
                            disabled={selectedDate === ""}
                            onChange={(e) => handleFilterChange(e)}
                          />
                          <label
                            className={classnames("form-check-label pt-1", {
                              "disabled-cursor": selectedDate === "",
                            })}
                            htmlFor="no_available_id"
                          >
                            No Availability
                          </label>
                        </div>
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="not_available_id"
                            name="notAvailable"
                            defaultChecked={selectedDate !== "" && notAvailable}
                            disabled={selectedDate === ""}
                            onChange={(e) => handleFilterChange(e)}
                          />
                          <label
                            className={classnames("form-check-label pt-1", {
                              "disabled-cursor": selectedDate === "",
                            })}
                            htmlFor="not_available_id"
                          >
                            Not Available
                          </label>
                        </div>
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="allocated_id"
                            name="allocated"
                            defaultChecked={selectedDate !== "" && allocated}
                            disabled={selectedDate === ""}
                            onChange={(e) => handleFilterChange(e)}
                          />
                          <label
                            className={classnames("form-check-label pt-1", {
                              "disabled-cursor": selectedDate === "",
                            })}
                            htmlFor="allocated_id"
                          >
                            Allocated Staff
                          </label>
                        </div>
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="supervisors_id"
                            name="supervisors"
                            defaultChecked={selectedDate !== "" && supervisors}
                            disabled={selectedDate === ""}
                            onChange={(e) =>
                              handleSupervisorAndMentorsFilter(e)
                            }
                          />
                          <label
                            className={classnames("form-check-label pt-1", {
                              "disabled-cursor": selectedDate === "",
                            })}
                            htmlFor="supervisors_id"
                          >
                            Supervisors
                          </label>
                        </div>
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="mentors_id"
                            name="mentors"
                            defaultChecked={selectedDate !== "" && mentors}
                            disabled={selectedDate === ""}
                            onChange={(e) =>
                              handleSupervisorAndMentorsFilter(e)
                            }
                          />
                          <label
                            className={classnames("form-check-label pt-1", {
                              "disabled-cursor": selectedDate === "",
                            })}
                            htmlFor="mentors_id"
                          >
                            Mentors
                          </label>
                        </div>
                        <div className="form-check ">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="un_allocated_id"
                            name="unallocated"
                            defaultChecked={selectedDate !== "" && unallocated}
                            disabled={selectedDate === ""}
                            onChange={(e) =>
                              handleSupervisorAndMentorsFilter(e)
                            }
                          />
                          <label
                            className={classnames("form-check-label pt-1", {
                              "disabled-cursor": selectedDate === "",
                            })}
                            htmlFor="un_allocated_id"
                          >
                            Highlight Unallocated
                          </label>
                        </div>
                      </div>
                    </Fragment>
                  )}
                  <div
                    className="calender-circle"
                    onClick={(e) => toggleCalenderPicker(e)}
                  >
                    <img src={calender} className="icon-filter" alt="arrow" />
                  </div>
                  {openDatePicker === true && (
                    <Fragment>
                      <img
                        src={filterArrow}
                        className="calender-arrow"
                        alt="arrow"
                      />
                      <DayPicker
                        className="calender animated animate__fadeInDown"
                        onDayClick={selectDate}
                        selectedDays={datesToAllocate}
                        modifiers={{ highlighted: highlightedDates }}
                        modifiersStyles={{
                          highlighted: {
                            backgroundColor: "#ff9900",
                          },
                        }}
                      />
                    </Fragment>
                  )}
                  <div
                    className="notification-circle "
                    onClick={(e) =>
                      selectedDate !== "" && toggleNotification(e)
                    }
                  >
                    <img src={bell} className="icon-filter" alt="arrow" />
                  </div>
                  <div className="notification-area " hidden={showNotification}>
                    <div>
                      User Comment :{" "}
                      {notificationData !== null && notificationData.comments}
                    </div>
                    <div>
                      Admin Notes :{" "}
                      {notificationData !== null && notificationData.adminNotes}
                    </div>
                    <div>
                      Allocation :{" "}
                      {notificationData !== null && notificationData.allocation}
                    </div>
                  </div>
                  <div className="date-content">{selectedDate}</div>
                  <div className="event-content">{eventInfo?.event}</div>
                  <div className="round-content">{eventInfo?.round}</div>
                  {showRoleToggle && (
                    <div className="toggle-content">
                      Additional Roles:{" "}
                      <Switch
                        onChange={onToggleAdditionalRoles}
                        checked={toggleRole}
                        loading={roleToggleLoading}
                      />
                    </div>
                  )}
                </div>
                {released === "2" && (
                  <>
                    <div
                      className="btn-undo-delete pl-5"
                      onClick={undoAllocations}
                    >
                      <img
                        src={undo}
                        className="icon-undo-delete"
                        alt="arrow"
                      />
                      <div className="small-name">Undo</div>
                    </div>
                    <div className="btn-delete " onClick={clearAllocations}>
                      <img
                        src={deleteBtn}
                        className="icon-undo-delete "
                        alt="arrow"
                      />
                      <div className="small-name">Clear All</div>
                    </div>
                  </>
                )}
                {selectedDate !== "" &&
                released !== "1" &&
                released !== null ? (
                  <>
                    <div
                      className={classnames("page-arrows", {
                        "disabled-cursor": rosterDataLoading,
                      })}
                      style={{ marginLeft: released !== "2" && "70px" }}
                      onClick={!rosterDataLoading ? onPreviousPageClick : null}
                    >
                      <img
                        src={arrowLeft}
                        className="icon-undo-delete"
                        alt="arrow"
                      />
                    </div>

                    <div
                      className={classnames("page-arrows", {
                        "disabled-cursor": rosterDataLoading,
                      })}
                      onClick={!rosterDataLoading ? onNextPageClick : null}
                    >
                      <img
                        src={arrowRight}
                        className="icon-undo-delete"
                        alt="arrow"
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {/* =========== courts part ========= */}

            <div className={`${courtName?.length > 8 && "scroll-container"}`}>
              <div className="row">
                <div className="courts-details mt-3">
                  <div
                    className="col-md-12 "
                    id="main"
                    onClick={(e) => onMenuRowClick(e)}
                  >
                    <div className="timer-section">
                      <div className="timer-icon ">
                        <img className="" src={timerIcon} alt="" />
                      </div>
                    </div>
                    <div className="court-section">
                      <div className=" courts-list">
                        {released === "1" && (
                          <div>
                            <div className="released-1-content">
                              In order to view this date, you first need to
                              close it from umpires entering their availability.
                              would you like to close this date?
                            </div>
                            <button
                              className="btn btn-sm released-1-btn"
                              onClick={(e) => onCloseDateClick(e)}
                            >
                              Close Date : {selectedDate}
                            </button>
                          </div>
                        )}
                        {released !== "1" && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {courtName?.length > 0 ? (
                              courtName.map((court, i) => (
                                <div
                                  key={i}
                                  id="main"
                                  className={classnames(
                                    "court-card w-100",

                                    {
                                      "length-1": courtName.length === 1,
                                    },
                                    {
                                      "length-2": courtName.length === 2,
                                    },
                                    {
                                      "length-3": courtName.length === 3,
                                    },
                                    {
                                      "length-4": courtName.length === 4,
                                    },
                                    {
                                      "length-5": courtName.length === 5,
                                    }
                                  )}
                                  onClick={(e) => onMenuRowClick(e)}
                                >
                                  <p className="court-title">
                                    {court?.courtName || ""}
                                  </p>
                                  <div
                                    className="sub-title"
                                    style={{
                                      width:
                                        fullWidth === true ? "100%" : "50%",
                                    }}
                                  >
                                    {court?.umpireMentorNameArray?.umpireName ||
                                      ""}
                                  </div>
                                  <div
                                    className="sub-title"
                                    style={{
                                      width: fullWidth === true ? "0%" : "50%",
                                      display: fullWidth === true && "none",
                                    }}
                                  >
                                    {court?.umpireMentorNameArray?.mentorName ||
                                      ""}
                                  </div>
                                </div>
                              ))
                            ) : rosterDataLoading && firstRosterLoading ? (
                              <div className="pt-2">
                                <CourtsLoader />
                              </div>
                            ) : (
                              <div className="no-courts">No Data Available</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* =========== Courts Part end ========== */}
              {/* =========== timing part-1 start ========= */}
              {released !== "1" &&
                mainTableArray.map((data, i) => (
                  <div className="row" key={i}>
                    <div className="time-slots-section mt-3">
                      <div className="col-md-12 ">
                        <div
                          className="timing-details bg-success "
                          onClick={(e) => onTimeSlotClick(data)}
                        >
                          <div
                            className={classnames(
                              "time-details",
                              {
                                "time-details-green":
                                  timeSlot === data.timeSlot,
                              },
                              {
                                "time-details-green":
                                  timesAvail.length > 0 &&
                                  timesAvail[0] !== "" &&
                                  timesAvail.includes(i.toString()),
                              }
                            )}
                            style={{
                              height:
                                toggleRole && showRoleToggle
                                  ? "250px"
                                  : supervisor_flag === 0
                                  ? "150px"
                                  : "200px",
                            }}
                          >
                            {rosterDataLoading && firstRosterLoading ? (
                              <TimeLoader />
                            ) : (
                              <span>{data.timeSlot || ""}</span>
                            )}
                          </div>
                        </div>
                        <div className="staff-section">
                          <div className=" staff-details ">
                            {data?.subData?.length > 0
                              ? data.subData.map((subData, i) => (
                                  <div className="w-100" key={i}>
                                    <ContextMenuTrigger
                                      id="game-menu"
                                      collect={(e) =>
                                        showContextMenu &&
                                        collectGameData(subData)
                                      }
                                    >
                                      <div
                                        className={classnames(
                                          {
                                            "staff-card":
                                              !toggleRole || !showRoleToggle,
                                            "staff-card-role":
                                              toggleRole && showRoleToggle,
                                          },
                                          {
                                            "bg-grey disabled-cursor":
                                              subData?.courtAllocationFlag ===
                                              1,
                                          },
                                          {
                                            "bg-light-pink":
                                              subData?.courtMatchFlag === 1,
                                          }

                                          // {
                                          //   "length-1": data.subData.length === 1,
                                          // },
                                          // {
                                          //   "length-2": data.subData.length === 2,
                                          // },
                                          // {
                                          //   "length-3": data.subData.length === 3,
                                          // },
                                          // {
                                          //   "length-4": data.subData.length === 4,
                                          // }
                                        )}
                                        style={{ width: "98%" }}
                                        key={i}
                                      >
                                        <div
                                          className="left-part"
                                          style={{
                                            width:
                                              fullWidth === true
                                                ? "100%"
                                                : "50%",
                                          }}
                                        >
                                          <div
                                            className={classnames(
                                              "name",
                                              {
                                                "custom-cursor":
                                                  subData.umpAName !== "",
                                              },
                                              {
                                                "highlight-background":
                                                  currentName &&
                                                  !rosterDataLoading &&
                                                  subData?.courtAllocationFlag !==
                                                    1 &&
                                                  selectedStaffName ===
                                                    subData.umpAName,
                                              },
                                              {
                                                "highlight-confirmed":
                                                  !currentName &&
                                                  subData?.courtAllocationFlag !==
                                                    1 &&
                                                  subData.umpAConfirmation ===
                                                    1,
                                              },
                                              {
                                                "disabled-cursor":
                                                  subData?.courtAllocationFlag ===
                                                  1,
                                              },
                                              {
                                                "bg-pink":
                                                  unallocated &&
                                                  subData?.courtAllocationFlag !==
                                                    1 &&
                                                  subData.umpAName === "",
                                              },
                                              {
                                                "hover-box":
                                                  currentTarget === "u1" &&
                                                  dragData?.timeID ===
                                                    subData?.timeID &&
                                                  currentCourtID ===
                                                    subData.courtID &&
                                                  subData.umpAName !== "",
                                              }
                                            )}
                                            draggable={
                                              subData?.courtAllocationFlag !==
                                                1 && subData.umpAName !== ""
                                            }
                                            onDragStart={(e) =>
                                              subData.umpAName !== ""
                                                ? subData?.courtAllocationFlag !==
                                                    1 &&
                                                  onDragStart(e, subData, "u1")
                                                : onDragCustom(e)
                                            }
                                            onDragOver={(e) =>
                                              subData.courtAllocationFlag !==
                                                1 &&
                                              onDragOver(e, subData, "u1")
                                            }
                                            onDrop={(e) =>
                                              subData.courtAllocationFlag !==
                                                1 && onDrop(e, subData, "u1")
                                            }
                                            onClick={(e) => {
                                              subData.umpAName === "" &&
                                                subData.courtAllocationFlag !==
                                                  1 &&
                                                callAPI &&
                                                allocateUserToGrid(
                                                  subData,
                                                  "umpA"
                                                );
                                            }}
                                          >
                                            {rosterDataLoading &&
                                            firstRosterLoading ? (
                                              <NameLoader />
                                            ) : (
                                              <span
                                                className={classnames(
                                                  {
                                                    "border-green":
                                                      subData.umpAavailablity ===
                                                      "2",
                                                  },
                                                  {
                                                    "border-red":
                                                      subData.umpAavailablity ===
                                                      "1",
                                                  },
                                                  {
                                                    "border-yellow":
                                                      subData.umpAavailablity ===
                                                      "0",
                                                  }
                                                )}
                                                onDoubleClick={(e) => {
                                                  subData.umpAName !== "" &&
                                                    subData.courtAllocationFlag !==
                                                      1 &&
                                                    removeUserFromGrid(
                                                      subData,
                                                      "umpA"
                                                    );
                                                }}
                                              >
                                                {subData.umpAName}
                                                {subData.umpANoteFlag == 1 && (
                                                  <span
                                                    className="mx-1 cursor-pointer"
                                                    title={subData.umpANote}
                                                    onClick={() =>
                                                      onEditUmpireNote(
                                                        subData,
                                                        subData.umpANote,
                                                        "umpA"
                                                      )
                                                    }
                                                  >
                                                    <i className="fa-regular fa-clipboard"></i>
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                          <div
                                            className={classnames(
                                              "name droppable",
                                              {
                                                "custom-cursor":
                                                  subData.umpBName !== "",
                                              },
                                              {
                                                "highlight-background":
                                                  currentName &&
                                                  !rosterDataLoading &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  selectedStaffName ===
                                                    subData.umpBName,
                                              },
                                              {
                                                "highlight-confirmed":
                                                  !currentName &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  subData.umpBConfirmation ===
                                                    1,
                                              },
                                              {
                                                "bg-pink":
                                                  unallocated &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  subData.umpBName === "",
                                              },
                                              {
                                                "disabled-cursor":
                                                  subData.courtAllocationFlag ===
                                                  1,
                                              },
                                              {
                                                "hover-box":
                                                  currentTarget === "u2" &&
                                                  dragData?.timeID ===
                                                    subData?.timeID &&
                                                  currentCourtID ===
                                                    subData.courtID &&
                                                  subData.umpBName !== "",
                                              }
                                            )}
                                            draggable={
                                              subData.courtAllocationFlag !==
                                                1 && subData.umpBName !== ""
                                            }
                                            onDragStart={(e) =>
                                              subData.umpBName !== ""
                                                ? subData.courtAllocationFlag !==
                                                    1 &&
                                                  onDragStart(e, subData, "u2")
                                                : onDragCustom(e)
                                            }
                                            onDragOver={(e) =>
                                              subData.courtAllocationFlag !==
                                                1 &&
                                              onDragOver(e, subData, "u2")
                                            }
                                            onDrop={(e) =>
                                              subData.courtAllocationFlag !==
                                                1 && onDrop(e, subData, "u2")
                                            }
                                            onClick={(e) =>
                                              subData.umpBName === "" &&
                                              subData.courtAllocationFlag !==
                                                1 &&
                                              callAPI &&
                                              allocateUserToGrid(
                                                subData,
                                                "umpB"
                                              )
                                            }
                                          >
                                            {rosterDataLoading &&
                                            firstRosterLoading ? (
                                              <NameLoader />
                                            ) : (
                                              <span
                                                className={classnames(
                                                  {
                                                    "border-green":
                                                      subData.umpBavailablity ===
                                                      "2",
                                                  },
                                                  {
                                                    "border-red":
                                                      subData.umpBavailablity ===
                                                      "1",
                                                  },
                                                  {
                                                    "border-yellow":
                                                      subData.umpBavailablity ===
                                                      "0",
                                                  }
                                                )}
                                                onDoubleClick={(e) => {
                                                  subData.umpBName !== "" &&
                                                    subData.courtAllocationFlag !==
                                                      1 &&
                                                    removeUserFromGrid(
                                                      subData,
                                                      "umpB"
                                                    );
                                                }}
                                              >
                                                {subData.umpBName}
                                                {subData.umpBNoteFlag == 1 && (
                                                  <span
                                                    className="mx-1 cursor-pointer"
                                                    title={subData.umpBNote}
                                                    onClick={() =>
                                                      onEditUmpireNote(
                                                        subData,
                                                        subData.umpBNote,
                                                        "umpB"
                                                      )
                                                    }
                                                  >
                                                    <i className="fa-regular fa-clipboard"></i>
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                        <div
                                          className="right-part"
                                          style={{
                                            width:
                                              fullWidth === true ? "0%" : "50%",
                                            display:
                                              fullWidth === true && "none",
                                          }}
                                        >
                                          {subData?.courtMatchFlag === 1 ? (
                                            <div className="red-flag-div">
                                              <img
                                                src={redFlagIcon}
                                                alt="red-flag-icon"
                                                className="red-flag-icon"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  info(subData.matchFlagReason);
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                          <div
                                            className={classnames(
                                              "name-1",
                                              {
                                                "custom-cursor":
                                                  subData.mentorAName !== "",
                                              },
                                              {
                                                "highlight-background":
                                                  currentName &&
                                                  !rosterDataLoading &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  selectedStaffName ===
                                                    subData.mentorAName,
                                              },
                                              {
                                                "bg-pink":
                                                  unallocated &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  subData.mentorAName === "",
                                              },
                                              {
                                                "highlight-confirmed":
                                                  !currentName &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  subData.mentorAConfirmation ===
                                                    1,
                                              },
                                              {
                                                "disabled-cursor":
                                                  subData.courtAllocationFlag ===
                                                  1,
                                              },
                                              {
                                                "hover-box":
                                                  currentTarget === "m1" &&
                                                  dragData?.timeID ===
                                                    subData?.timeID &&
                                                  currentCourtID ===
                                                    subData.courtID &&
                                                  subData.mentorAName !== "",
                                              }
                                            )}
                                            draggable={
                                              subData.courtAllocationFlag !==
                                                1 && subData.mentorAName !== ""
                                            }
                                            onDragStart={(e) =>
                                              subData.mentorAName !== ""
                                                ? subData.courtAllocationFlag !==
                                                    1 &&
                                                  onDragStart(e, subData, "m1")
                                                : onDragCustom(e)
                                            }
                                            onDragOver={(e) =>
                                              subData.courtAllocationFlag !==
                                                1 &&
                                              onDragOver(e, subData, "m1")
                                            }
                                            onDrop={(e) =>
                                              subData.courtAllocationFlag !==
                                                1 && onDrop(e, subData, "m1")
                                            }
                                            onClick={(e) =>
                                              subData.mentorAName === "" &&
                                              subData.courtAllocationFlag !==
                                                1 &&
                                              callAPI &&
                                              allocateUserToGrid(
                                                subData,
                                                "mentorA"
                                              )
                                            }
                                            style={{
                                              paddingTop:
                                                subData?.courtMatchFlag === 1
                                                  ? "0px"
                                                  : "inherit",
                                            }}
                                          >
                                            {rosterDataLoading &&
                                            firstRosterLoading ? (
                                              <NameLoader />
                                            ) : (
                                              <span
                                                className={classnames(
                                                  {
                                                    "border-green":
                                                      subData.mentorAavailablity ===
                                                      "2",
                                                  },
                                                  {
                                                    "border-red":
                                                      subData.mentorAavailablity ===
                                                      "1",
                                                  },
                                                  {
                                                    "border-yellow":
                                                      subData.mentorAavailablity ===
                                                      "0",
                                                  }
                                                )}
                                                onDoubleClick={(e) => {
                                                  subData.mentorAName !== "" &&
                                                    subData.courtAllocationFlag !==
                                                      1 &&
                                                    removeUserFromGrid(
                                                      subData,
                                                      "mentorA"
                                                    );
                                                }}
                                              >
                                                {subData.mentorAName}
                                                {subData.mentorANoteFlag ==
                                                  1 && (
                                                  <span
                                                    className="mx-1 cursor-pointer"
                                                    title={subData.mentorANote}
                                                    onClick={() =>
                                                      onEditUmpireNote(
                                                        subData,
                                                        subData.mentorANote,
                                                        "mentorA"
                                                      )
                                                    }
                                                  >
                                                    <i className="fa-regular fa-clipboard"></i>
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                          <div
                                            className={classnames(
                                              "name-2",
                                              {
                                                "custom-cursor":
                                                  subData.mentorBName !== "",
                                              },
                                              {
                                                "highlight-background":
                                                  currentName &&
                                                  !rosterDataLoading &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  selectedStaffName ===
                                                    subData.mentorBName,
                                              },
                                              {
                                                "bg-pink":
                                                  unallocated &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  subData.mentorBName === "",
                                              },
                                              {
                                                "highlight-confirmed":
                                                  !currentName &&
                                                  subData.courtAllocationFlag !==
                                                    1 &&
                                                  subData.mentorBConfirmation ===
                                                    1,
                                              },
                                              {
                                                "hover-box":
                                                  currentTarget === "m2" &&
                                                  dragData?.timeID ===
                                                    subData?.timeID &&
                                                  currentCourtID ===
                                                    subData.courtID &&
                                                  subData.mentorBName !== "",
                                              },
                                              {
                                                "disabled-cursor":
                                                  subData.courtAllocationFlag ===
                                                  1,
                                              }
                                            )}
                                            draggable={
                                              subData.courtAllocationFlag !==
                                                1 && subData.mentorBName !== ""
                                            }
                                            onDragStart={(e) =>
                                              subData.mentorBName !== ""
                                                ? subData.courtAllocationFlag !==
                                                    1 &&
                                                  onDragStart(e, subData, "m2")
                                                : onDragCustom(e)
                                            }
                                            onDragOver={(e) =>
                                              subData.courtAllocationFlag !==
                                                1 &&
                                              onDragOver(e, subData, "m2")
                                            }
                                            onDrop={(e) =>
                                              subData.courtAllocationFlag !==
                                                1 && onDrop(e, subData, "m2")
                                            }
                                            onClick={(e) =>
                                              subData.mentorBName === "" &&
                                              subData.courtAllocationFlag !==
                                                1 &&
                                              callAPI &&
                                              allocateUserToGrid(
                                                subData,
                                                "mentorB"
                                              )
                                            }
                                          >
                                            {rosterDataLoading &&
                                            firstRosterLoading ? (
                                              <NameLoader />
                                            ) : (
                                              <span
                                                className={classnames(
                                                  {
                                                    "border-green":
                                                      subData.mentorBavailablity ===
                                                      "2",
                                                  },
                                                  {
                                                    "border-red":
                                                      subData.mentorBavailablity ===
                                                      "1",
                                                  },
                                                  {
                                                    "border-yellow":
                                                      subData.mentorBavailablity ===
                                                      "0",
                                                  }
                                                )}
                                                onDoubleClick={(e) => {
                                                  subData.mentorBName !== "" &&
                                                    subData.courtAllocationFlag !==
                                                      1 &&
                                                    removeUserFromGrid(
                                                      subData,
                                                      "mentorB"
                                                    );
                                                }}
                                              >
                                                {subData.mentorBName}
                                                {subData.mentorBNoteFlag ==
                                                  1 && (
                                                  <span
                                                    className="mx-1 cursor-pointer"
                                                    title={subData.mentorBNote}
                                                    onClick={() =>
                                                      onEditUmpireNote(
                                                        subData,
                                                        subData.mentorBNote,
                                                        "mentorB"
                                                      )
                                                    }
                                                  >
                                                    <i className="fa-regular fa-clipboard"></i>
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        </div>

                                        {toggleRole && showRoleToggle && (
                                          <div className="role-part">
                                            {rosterDataLoading &&
                                            firstRosterLoading ? (
                                              <MatchDataLoader />
                                            ) : (
                                              <div className="px-0">
                                                {subData?.additionalRoles
                                                  ?.length > 0 &&
                                                  subData.additionalRoles.map(
                                                    (obj, indx) => (
                                                      <div
                                                        className="mt-1 d-flex justify-content-between align-items-center"
                                                        key={indx}
                                                      >
                                                        <span
                                                          className="mx-1 mt-1 cursor-pointer"
                                                          onClick={() =>
                                                            onRemoveUmpireRole(
                                                              subData,
                                                              obj.roleUserId
                                                            )
                                                          }
                                                        >
                                                          {obj.roleUserName} (
                                                          {obj.roleName})
                                                        </span>
                                                        <div className="edit_icon_div mt-0">
                                                          <img
                                                            src={editIcon}
                                                            alt="edit-icon"
                                                            style={{
                                                              width: "12px",
                                                              borderRadius:
                                                                "0px",
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              onEditUmpireRole(
                                                                subData,
                                                                obj.roleName,
                                                                obj.roleUserId
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                              </div>
                                            )}
                                          </div>
                                        )}

                                        <div
                                          className={classnames({
                                            "bottom-part":
                                              !toggleRole || !showRoleToggle,
                                            "bottom-part-role":
                                              toggleRole && showRoleToggle,
                                          })}
                                        >
                                          {rosterDataLoading &&
                                          firstRosterLoading ? (
                                            <MatchDataLoader />
                                          ) : (
                                            <div className="px-0">
                                              {/* {subData?.additionalRoles.length >
                                                0 &&
                                                subData.additionalRoles.map(
                                                  (obj, indx) => (
                                                    <div
                                                      className="mt-1 d-flex justify-content-between align-items-center"
                                                      key={indx}
                                                    >
                                                      <span
                                                        className="mx-1 mt-1 cursor-pointer"
                                                        onClick={() =>
                                                          onRemoveUmpireRole(
                                                            subData,
                                                            obj.roleUserId
                                                          )
                                                        }
                                                      >
                                                        {obj.roleUserName} (
                                                        {obj.roleName})
                                                      </span>
                                                      <div className="edit_icon_div mt-0">
                                                        <img
                                                          src={editIcon}
                                                          alt="edit-icon"
                                                          style={{
                                                            width: "12px",
                                                            borderRadius: "0px",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            onEditUmpireRole(
                                                              subData,
                                                              obj.roleName,
                                                              obj.roleUserId
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  )
                                                )} */}
                                              <div className="edit_icon_div">
                                                <img
                                                  src={editIcon}
                                                  alt="edit-icon"
                                                  style={{
                                                    width: "12px",
                                                    borderRadius: "0px",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleCourtModal(
                                                      subData.matchData,
                                                      subData
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="court-info">
                                                {subData.matchData.grade}
                                              </div>
                                              <div className="court-info">
                                                {subData.matchData.home_team}
                                              </div>
                                              <div className="court-info">
                                                {subData.matchData.away_team}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      {showContextMenu && (
                                        <ContextMenu
                                          id="game-menu"
                                          className="context-menu-2"
                                        >
                                          {gameData !== null &&
                                          gameData?.courtAllocationFlag === 0 &&
                                          gameData?.courtMatchFlag === 0 ? (
                                            <>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={handleGameMenu}
                                                data={{ game: "false" }}
                                              >
                                                Set to no Game
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleFlagMatch(e, gameData)
                                                }
                                              >
                                                Set Flag Match
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleOpenNote(e, gameData)
                                                }
                                              >
                                                Add Umpire Note
                                              </MenuItem>
                                              {toggleRole && showRoleToggle && (
                                                <MenuItem
                                                  className="cursor-pointer"
                                                  onClick={(e) =>
                                                    handleOpenRole(e, gameData)
                                                  }
                                                >
                                                  Add Additional Roles
                                                </MenuItem>
                                              )}
                                            </>
                                          ) : gameData?.courtAllocationFlag ===
                                              0 &&
                                            gameData?.courtMatchFlag === 1 ? (
                                            <>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={handleGameMenu}
                                                data={{ game: "false" }}
                                              >
                                                Set to no Game
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleRemoveFlagMatch(
                                                    e,
                                                    gameData
                                                  )
                                                }
                                              >
                                                Unset Flag Match
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleOpenNote(e, gameData)
                                                }
                                              >
                                                Add Umpire Note
                                              </MenuItem>
                                              {toggleRole && showRoleToggle && (
                                                <MenuItem
                                                  className="cursor-pointer"
                                                  onClick={(e) =>
                                                    handleOpenRole(e, gameData)
                                                  }
                                                >
                                                  Add Additional Roles
                                                </MenuItem>
                                              )}
                                            </>
                                          ) : gameData?.courtAllocationFlag ===
                                              1 &&
                                            gameData?.courtMatchFlag === 0 ? (
                                            <>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={handleGameMenu}
                                                data={{ game: "true" }}
                                              >
                                                Set back to Game
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleFlagMatch(e, gameData)
                                                }
                                              >
                                                Set Flag Match
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleOpenNote(e, gameData)
                                                }
                                              >
                                                Add Umpire Note
                                              </MenuItem>
                                              {toggleRole && showRoleToggle && (
                                                <MenuItem
                                                  className="cursor-pointer"
                                                  onClick={(e) =>
                                                    handleOpenRole(e, gameData)
                                                  }
                                                >
                                                  Add Additional Roles
                                                </MenuItem>
                                              )}
                                            </>
                                          ) : gameData?.courtAllocationFlag ===
                                              1 &&
                                            gameData?.courtMatchFlag === 1 ? (
                                            <>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={handleGameMenu}
                                                data={{ game: "true" }}
                                              >
                                                Set back to Game
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleRemoveFlagMatch(
                                                    e,
                                                    gameData
                                                  )
                                                }
                                              >
                                                Unset Flag Match
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleOpenNote(e, gameData)
                                                }
                                              >
                                                Add Umpire Note
                                              </MenuItem>
                                              {toggleRole && showRoleToggle && (
                                                <MenuItem
                                                  className="cursor-pointer"
                                                  onClick={(e) =>
                                                    handleOpenRole(e, gameData)
                                                  }
                                                >
                                                  Add Additional Roles
                                                </MenuItem>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={handleGameMenu}
                                                data={{ game: "true" }}
                                              >
                                                Set back to Game
                                              </MenuItem>
                                              <MenuItem
                                                className="cursor-pointer"
                                                onClick={(e) =>
                                                  handleOpenNote(e)
                                                }
                                              >
                                                Add Umpire Note
                                              </MenuItem>
                                              {toggleRole && showRoleToggle && (
                                                <MenuItem
                                                  className="cursor-pointer"
                                                  onClick={(e) =>
                                                    handleOpenRole(e, gameData)
                                                  }
                                                >
                                                  Add Additional Roles
                                                </MenuItem>
                                              )}
                                            </>
                                          )}
                                        </ContextMenu>
                                      )}
                                    </ContextMenuTrigger>
                                  </div>
                                ))
                              : null}
                          </div>

                          <div
                            id="allocate-s"
                            className="bottom-info"
                            style={{
                              display:
                                supervisor_flag === 0 ? "none" : "inherit",
                              height: !supervisors && "0px",
                              padding: !supervisors && "0px",
                              padding:
                                supervisors && showSupervisors && "15px 10px",
                            }}
                            onClick={(e) =>
                              selectedStaffName !== "" &&
                              currentName &&
                              callAPI &&
                              allocateSupervisorToGrid(e, data)
                            }
                          >
                            {supervisors && (
                              <div
                                style={{
                                  maxHeight: !supervisors && "0px",
                                }}
                                id="allocate-s"
                              >
                                {showSupervisors && (
                                  <>
                                    Supervisors:{" "}
                                    {data.supervisorList.length > 0 ? (
                                      data.supervisorList.map(
                                        (supervisor, i) => {
                                          const name =
                                            supervisor.givenName +
                                            " " +
                                            supervisor.lastName;
                                          return (
                                            <div
                                              className={classnames("d-inline")}
                                              key={i}
                                              id="allocate-s"
                                            >
                                              {!rosterDataLoading &&
                                                i !== 0 &&
                                                ",     "}
                                              <span
                                                id="remove-s"
                                                className={classnames(
                                                  "supervisor-name",
                                                  {
                                                    "border-yellow":
                                                      supervisor.superVisorAavailablity ===
                                                      "0",
                                                  },
                                                  {
                                                    "border-red":
                                                      supervisor.superVisorAavailablity ===
                                                      "1",
                                                  },
                                                  {
                                                    "border-green":
                                                      supervisor.superVisorAavailablity ===
                                                      "2",
                                                  },
                                                  {
                                                    "highlight-background":
                                                      currentName &&
                                                      !rosterDataLoading &&
                                                      selectedStaffName ===
                                                        name,
                                                  }
                                                )}
                                                onDoubleClick={(e) =>
                                                  removeSupervisorFromGrid(
                                                    e,
                                                    supervisor,
                                                    data
                                                  )
                                                }
                                              >
                                                {supervisor.givenName}{" "}
                                                {supervisor.lastName}
                                              </span>
                                              {firstRosterLoading &&
                                              rosterDataLoading ? (
                                                <SupervisorLoader />
                                              ) : null}
                                            </div>
                                          );
                                        }
                                      )
                                    ) : (
                                      <span
                                        id="allocate-s"
                                        className={classnames("pr-2")}
                                      >
                                        No supervisors found
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {courtGameModal === true ? (
        <Modal
          // title=" "
          closable={false}
          visible={courtGameModal}
          onCancel={handleCancel}
          onOk={handleCourtSubmit}
        >
          <h5 className="modal-heading">Grade</h5>
          <Input
            type="text"
            className={courtGameError.grade_error ? "" : "modal-input"}
            value={courtGameData.grade}
            onChange={(e) => handleInputData(e, 1)}
            placeholder="Enter Grade"
          />
          {courtGameError.grade_error ? (
            <p style={{ color: "red", marginBottom: "4px" }}>
              {courtGameError.grade_error}
            </p>
          ) : (
            ""
          )}

          <h5 className="modal-heading">Home Team</h5>
          <Input
            type="text"
            className={courtGameError.home_team_error ? "" : "modal-input"}
            value={courtGameData.home_team}
            onChange={(e) => handleInputData(e, 2)}
            placeholder="Enter Home Team"
          />
          {courtGameError.home_team_error ? (
            <p style={{ color: "red", marginBottom: "4px" }}>
              {courtGameError.home_team_error}
            </p>
          ) : (
            ""
          )}

          <h5 className="modal-heading">Away Team</h5>
          <Input
            type="text"
            className={courtGameError.away_team_error ? "" : "modal-input"}
            value={courtGameData.away_team}
            onChange={(e) => handleInputData(e, 3)}
            placeholder="Enter Away Team"
          />
          {courtGameError.away_team_error ? (
            <p style={{ color: "red", marginBottom: "4px" }}>
              {courtGameError.away_team_error}
            </p>
          ) : (
            ""
          )}

          <h5 className="edit_history_label">Edit History</h5>
          <div className={editHistory?.length > 4 ? "Edit_History" : ""}>
            <table className="modal-table">
              <tr>
                <th>Date</th>
                <th>Grade</th>
                <th>Home Team</th>
                <th>Away Team</th>
              </tr>
              {editHistory?.length > 0 ? (
                editHistory?.map((edit_data, i) => (
                  <tr>
                    <td>{edit_data?.crt_date?.split(" ")[0]}</td>
                    <td>{edit_data?.grade}</td>
                    <td>{edit_data?.home_team}</td>
                    <td>{edit_data?.away_team}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} style={{ textAlign: "center" }}>
                    No record found
                  </td>
                </tr>
              )}
            </table>
          </div>
        </Modal>
      ) : (
        ""
      )}

      {updateFlagModal === true ? (
        <Modal
          title="Match flag reason"
          closable={true}
          visible={updateFlagModal}
          onCancel={handleUpdateFlagModalCancel}
          onOk={handleUpdateFlagModalSubmit}
          confirmLoading={updateLoading}
        >
          <Input
            type="text"
            className=""
            value={updateFlagText}
            onChange={(e) => setUpdateFlagText(e.target.value)}
            placeholder="Please provide a reason to set match flag"
          />
          {updateFlagTextErr ? (
            <p style={{ color: "red", marginBottom: "4px" }}>
              {updateFlagTextErr}
            </p>
          ) : (
            ""
          )}
        </Modal>
      ) : null}

      {removeFlagModal === true ? (
        <Modal
          title="Remove match flag"
          closable={true}
          visible={removeFlagModal}
          onCancel={handleCancelFlagModalCancel}
          onOk={handleCancelFlagModalSubmit}
          confirmLoading={removeLoading}
        >
          <h5>Are you sure want to remove match flag</h5>
        </Modal>
      ) : null}

      {reReleaseModal === true && (
        <Modal
          // title=" "
          closable={false}
          visible={reReleaseModal}
          onCancel={handleReReleaseCancel}
          cancelButtonProps={{ style: { zIndex: 0 } }}
          okButtonProps={{ style: { zIndex: 0 } }}
          onOk={handleReReleaseSubmit}
        >
          <h5 className="modal-heading">Select Users:</h5>
          <p className="mt-1 mb-2" style={{ fontSize: "10px" }}>
            Please select the users you wish to re-send the roster release to,
            to notify them that their roster has changed and to confirm.
          </p>

          <div
            style={{
              margin: "10px 0",
            }}
          >
            <Select
              defaultValue={[]}
              isMulti
              // isOptionDisabled={() => userValue.length >= 5}
              styles={customStyles}
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              onChange={(u, e) => handleChange(u, e)}
              options={dropdownData}
              value={dropdownData?.filter((obj) =>
                userValue.includes(obj.value)
              )}
              components={{
                Option: InputOption,
              }}
              placeholder="Select"
              name="sire_profile"
              isClearable
            />
          </div>
        </Modal>
      )}

      {showNoteModal && (
        <Modal
          closable={false}
          visible={showNoteModal}
          onCancel={handleCancelUmpireNote}
          onOk={handleSubmitUmpireNote}
        >
          <div className="mb-2">
            <Select
              options={noteUmpireList}
              placeholder="Select Umpire"
              name="select_umpire"
              styles={customStyles}
              defaultValue={[]}
              onChange={handleSelectUmpireForNote}
              value={noteUmpireList.find(
                (option) => option.value === selectedUmpireForNote
              )}
            />

            {umpireNoteError.umpire_select_error && (
              <p style={{ color: "red", marginBottom: "4px" }}>
                {umpireNoteError.umpire_select_error}
              </p>
            )}
          </div>

          <h5 className="modal-heading">Note</h5>
          <Input.TextArea
            rows={3}
            className="note-input"
            value={umpireNote}
            onChange={(e) => handleChangeUmpireNote(e, 1)}
            placeholder="Add Note"
          />

          {umpireNoteError.note_error && (
            <p style={{ color: "red", marginBottom: "4px" }}>
              {umpireNoteError.note_error}
            </p>
          )}
        </Modal>
      )}

      {showRoleModal && (
        <Modal
          closable={false}
          visible={showRoleModal}
          onCancel={handleCancelUmpireRole}
          onOk={handleSubmitUmpireRole}
        >
          <div className="mb-2">
            <Select
              options={roleUmpireList}
              placeholder="Select Umpire"
              name="select_umpire"
              styles={customStyles}
              defaultValue={[]}
              onChange={handleSelectUmpireForRole}
              value={roleUmpireList.find(
                (option) => option.value === selectedUmpireForRole
              )}
            />

            {umpireRoleError.umpire_select_error && (
              <p style={{ color: "red", marginBottom: "4px" }}>
                {umpireRoleError.umpire_select_error}
              </p>
            )}
          </div>

          <h5 className="modal-heading">Role</h5>
          <Input
            className="note-input"
            value={umpireRole}
            onChange={(e) => handleChangeUmpireRole(e)}
            placeholder="Add Role"
          />

          {umpireRoleError.role_error && (
            <p style={{ color: "red", marginBottom: "4px" }}>
              {umpireRoleError.role_error}
            </p>
          )}
        </Modal>
      )}

      {showEventModal && (
        <Modal
          closable={false}
          visible={showEventModal}
          onCancel={handleCancelEvents}
          onOk={handleSubmitEvents}
        >
          <div className="mb-2">
            <Select
              options={events}
              placeholder="Select Event"
              name="select_event"
              styles={customStyles}
              defaultValue={[]}
              onChange={handleSelectEvents}
              value={events.find((option) => option.value === selectedEvent)}
            />

            {eventError && (
              <p style={{ color: "red", marginBottom: "4px" }}>{eventError}</p>
            )}
          </div>
        </Modal>
      )}
    </Fragment>
  );
};
const mapStateToProps = (state) => ({
  roster: state.roster,
  staff: state.staff,
});
export default connect(mapStateToProps, {
  swapUsers,
  updateUserDetails,
  getUserDetails,
  getStaffList,
  getCombinedData,
  getDatesForAllocation,
  updateAllocationData,
  closeAllocationDate,
  gameLabelChange,
  userCheckAuth,
  updateFlagMatch,
  removeFlagMatch,
  getAllocatedShiftUsers,
  ReReleaseRoasterSend,
  addUmpireNote,
  addAdditionalRole,
  removeAdditionalRole,
  getMultipleEvents,
  updateAdditionalRoleFlag,
})(Roster);
